import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiToggle, UiInput, UiButton, UiRSelect } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { arrayMoveImmutable as arrayMove } from "array-move";
import { Checkbox, List, Space } from 'antd';
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { RiDraggable } from "react-icons/ri";

const ITEM_TYPE = "COLUMN";



const CustomColumn = NiceModal.create(
  ({ initialColumns, columnOrder, visibleColumns }) => {
    const modal = useModal();
    const customizableColumns = initialColumns.filter((col) => col.customizable);
    const [columns, setColumns] = useState(customizableColumns);
    const [visibleColumns1, setVisibleColumns1] = useState(visibleColumns);
    const [selectedColumns1, setSelectedColumns1] = useState(columnOrder);


    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
    }, [location]);


    useEffect(() => {
      setColumns(columnOrder.map((key) => customizableColumns.find((col) => col.key === key)));
    }, []);


    const DraggableItem = ({ column, index, moveItem, toggleVisibility }) => {
      const ref = React.useRef(null);

      const [, drag] = useDrag({
        type: ITEM_TYPE,
        item: { index },
      });

      const [, drop] = useDrop({
        accept: ITEM_TYPE,
        hover: (draggedItem) => {
          if (draggedItem.index !== index) {
            moveItem(draggedItem.index, index);
            draggedItem.index = index;
          }
        },
      });

      drag(drop(ref));

      return (
        <li ref={ref} className="bg-light rounded p-2 mb-2">
          <Checkbox checked={visibleColumns1.includes(column.key)} onChange={() => toggleVisibility(column.key)}>
            {column.title}
          </Checkbox>
          <span style={{ float: "right", cursor: "grab" }}><RiDraggable/></span>
        </li>
      );
    };


    const moveItem = (oldIndex, newIndex) => {
      const newColumns = arrayMove(columns, oldIndex, newIndex);
      const columnKeys = newColumns.map((col) => col.key);
      setSelectedColumns1(columnKeys);
      setColumns(newColumns);
    };

    const toggleVisibility = (key1) => {
      const isVisible = visibleColumns1.includes(key1);
      if (isVisible) {
        
        setVisibleColumns1(visibleColumns1.filter((key) => key !== key1));
      } else {
        setVisibleColumns1([...visibleColumns1, key1]);
      }
    };


    const saveColumns = () => {
      modal.resolve({ columnOrder: selectedColumns1, visibleColumns: visibleColumns1 });
      modal.hide();
    }


    return (
      <BootstrapModal {...bootstrapDialog(modal)}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Customize Columns</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body>


          <DndProvider backend={HTML5Backend}>
            <ul style={{ listStyle: "none", padding: 0 }}>
              {columns.map((column, index) => (
                <DraggableItem
                  key={column.key}
                  column={column}
                  index={index}
                  moveItem={moveItem}
                  toggleVisibility={toggleVisibility}
                />
              ))}
            </ul>
          </DndProvider>




        </BootstrapModal.Body>
        <BootstrapModal.Footer>
          <UiButton onClick={() => saveColumns()} title="Save" />
          <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
        </BootstrapModal.Footer>

      </BootstrapModal>
    );
  }
);

export default CustomColumn;
