import React, { forwardRef, useState, useEffect } from "react";
import { rupees } from "./helper";


export function ReceiptTemplate(props) {

  const { data } = props

  return (

    <>
      {data.cancel == 1 &&
        <div className="alert alert-danger text-center">
          <div className="text-uppercase">Cancelled</div>
        </div>}

      <div className="row">
        <div className="col-sm-6">
          {data.logo && (
            <img style={{ maxWidth: '200px' }} src={data.logo} />
          )}
          <p style={{ maxWidth: '400px' }}><b className="fs-5">{data.company}</b><br></br>
            {data.company_address && data.company_address.split("\n").map(function (item) {
              return (
                <>
                  {item}
                  <br />
                </>
              )
            })}{data.company_state}
            {data.mobile && (<><br></br><b>Mobile: </b>{data.mobile}</>)}
            {data.email && (<><br></br><b>Email: </b>{data.email}</>)}
          </p>
          {data.company_gstin && (
            <p><b>GSTIN: </b>{data.company_gstin}</p>
          )}
        </div>
        <div className="col-sm-6 text-sm-end">
          <div className="text-grey-m2">
            <div className="h1 text-600">
              {data.receipt_heading}
            </div>

          </div>
        </div>
      </div>


      <hr className="row brc-default-l1 mx-n1 mb-4" />

      <div className="row">
        <div className="col-sm-6">
          <div>
            <p className="mb-1">Received From:</p>
            <span className="fw-semibold">{data.client}</span>
          </div>
          <div className="text-grey-m2">
            <p style={{ maxWidth: '400px' }}>{data.address && data.address}
              {data.city && <><br></br>{data.city} {data.pincode && <> - {data.pincode}</>}</>}
              {data.state ? <><br></br>{data.state}</> : ''}
              {data.client_mobile && (<><br></br><b>Mobile: </b>{data.client_mobile}</>)}
              {data.client_email && (<><br></br><b>Email: </b>{data.client_email}</>)}
            </p>


          </div>
        </div>


        <div className="col-sm-6 text-sm-end">
          <div className="">
            <table className="table">
              <tr>
                <td>Receipt No.:</td>
                <td className="text-end">
                  {data.receipt_id}
                </td>
              </tr>
              <tr>
                <td>Receipt Date:</td>
                <td className="text-end">
                  {data.date}
                </td>
              </tr>

            </table>
          </div>
        </div>

      </div>
      {data.cancel == 0 && (<>
        <div className="table-responsive mt-4 mb-9">
          <table className="table mb-3">
            <thead>
              <tr className="text-secondary text-uppercase">
                <th className="">Description</th>

                <th className="text-end">Amount</th>
              </tr>
            </thead>
            <tbody>

              <tr className="">
                <td>
                  Payment received through {data.mode}<br></br>
                  {(data.show_remark == 1 && data.remarks) && <>{`(${data.remarks})`}</>}
                </td>

                <td className="text-end">{rupees(data.subtotal)}</td>
              </tr>



            </tbody>
          </table>
        </div>
        <div>
          <div className="row">
            <div className="col-md-8">
              <p className="mb-4">{data.total_in_words}</p>
              {data.items && data.items.length > 0 && <>
                <h6>Payment settled to following</h6>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Invoice No.</th>
                      <th>Invoice Amount</th>
                      <th>Payment</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.items.map((val, i) =>
                      <tr>
                        <td>{val.date}</td>
                        <td>{val.invoice_id}</td>
                        <td>{rupees(val.total)}</td>
                        <td>{rupees(val.paid)}</td>
                      </tr>
                    )}

                  </tbody>
                </table>
              </>}
            </div>
            <div className="col-md-4 mt-3">
              <div className="d-flex justify-content-end">

                <table className="table table-borderless table-sm" style={{ maxWidth: '300px' }}>
                  <tbody>
                    {(data.discount > 0 || data.tds > 0) &&
                      <tr>
                        <td className="">Subtotal:</td>
                        <td className="text-end">
                          {rupees(data.subtotal)}
                        </td>
                      </tr>
                    }
                    {data.discount > 0 &&
                      <tr>
                        <td className="">Discount:</td>
                        <td className="text-end">
                          {rupees(data.discount)}
                        </td>
                      </tr>
                    }
                    {data.tds > 0 &&
                      <tr>
                        <td className="">TDS:</td>
                        <td className="text-end">
                          {rupees(data.tds)}
                        </td>
                      </tr>
                    }
                    <tr className="border-top border-bottom">
                      <td className="fs-5">Total:</td>
                      <td className="text-end fs-5 fw-semibold">
                        {rupees(data.total)}
                      </td>
                    </tr>
                    {data.receipt_ledger_bal == 1 && <>
                      <tr className="">
                        <td className="">Current Ledger Balance:</td>
                        <td className="text-end ">
                          {rupees(data.balance)}
                        </td>
                      </tr>




                    </>}
                  </tbody>
                </table>

              </div>



            </div>
          </div>
        </div>

      </>)}
      <div className="row">
        <div className="col-md-7">
          <div className="mt-4 bill-footer" dangerouslySetInnerHTML={{
            __html: data.receipt_footer
          }}>
          </div>
        </div>
        <div className="col-md-5 text-end pt-4">
          {data.sign && (
            <img style={{ maxWidth: '200px' }} src={data.sign} />
          )}
          <p style={{ marginTop: data.sign ? '10px' : '100px' }}>For {data.company}</p>
        </div>
      </div>



    </>

  )
}

