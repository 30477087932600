import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { UiTextArea, UiSelect, TableZero, UiDatePicker, UiButton, UiRSelect, UiInput, UiRichText, PageHeader, PageError, UiToggle } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../../axios";
import { useNavigate, useLocation } from "react-router-dom";
import { Table } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Swal from "sweetalert2";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";



export default function SendNotifications() {

  const [state, setState] = useState({});
  const [loader, showLoader] = useState(false);
  const [loader1, showLoader1] = useState(false);
  const [steptwo, showSteptwo] = useState(false);
  const [notifType, setType] = useState('task');

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const VariableCopy = (props) => {
    const { items } = props;
    return (
      <>
        {items.map((val, ix) => (
          <OverlayTrigger key={ix} overlay={<Tooltip >Click to copy</Tooltip>} >
            <CopyToClipboard text={val}>
              <span type="button" className="btn btn-light me-2 btnpulse">{val}</span>
            </CopyToClipboard>
          </OverlayTrigger>
        ))}
      </>
    )
  }

  const schema = yup.object().shape({

    type: yup.string(),
    service: yup.object().nullable().when('type', {
      is: (value) => value == 'task',
      then: (rule) => rule.required("Select Service")
    }),

    due_type: yup.object().nullable().when('type', {
      is: (value) => value == 'payment_reminder',
      then: (rule) => rule.required("This is a required field")
    }),

    cust_type: yup.object().nullable().when('type', {
      is: (value) => value == 'custom',
      then: (rule) => rule.required("This is a required field")
    }),

    task_created: yup.bool(),
    fyear: yup.string().when(['task_created', 'service'], {
      is: (task_created, service) => { if (task_created && service && service.freq == 'Yearly') { return true; } },
      then: (rule) => rule.required("Select year")
    }),

    month_year: yup.string().when(['task_created', 'service'], {
      is: (task_created, service) => { if (task_created && service && service.freq == 'Monthly') { return true; } },
      then: (rule) => rule.required("Select month & year")
    }),

    quarter: yup.string().when(['task_created', 'service'], {
      is: (task_created, service) => { if (task_created && service && service.freq == 'Quarterly') { return true; } },

      then: (rule) => rule.required("Select quarter")


    }),

    hy_period: yup.string().when(['task_created', 'service'], {
      is: (task_created, service) => { if (task_created && service && service.freq == 'Half-Yearly') { return true; } },

      then: (rule) => rule.required("Select period")


    }),

    year: yup.string().when(['task_created', 'service'], {
      is: (task_created, service) => { if (task_created && service && ['Quarterly', 'Half-Yearly'].includes(service.freq)) { return true; } },
      then: (rule) => rule.required("Select year")


    }),

    status: yup.array().when('task_created', {
      is: true,
      then: yup.array().min(1, 'Please select status').required("Please select status")
    }),


  });



  const schema1 = yup.object().shape({

    channel: yup.string().nullable().required('Please select'),
    sms_template: yup.string().nullable().when("channel", {
      is: 'sms',
      then: yup.string().nullable().required("Select SMS template")
    }),



    whatsapp_template: yup.string().nullable().when("channel", {
      is: 'whatsapp',
      then: yup.string().nullable().required("Select WhatsApp template")
    }),

    email_template: yup.string().nullable().when('channel', {
      is: 'email',
      then: yup.string().nullable().required("Email template is required").max(3000, 'Maximum character limit is 3000')
    }),
    email_subject: yup.string().nullable().when('channel', {
      is: 'email',
      then: yup.string().nullable().required("Email subject is required").max(100, 'Maximum character limit is 100')
    }),

  });
  var handleSave = (e) => {
    showLoader(true)
    var form = document.querySelector("#notificationfilter");
    var data = new FormData(form);

    instance({
      method: "post",
      url: "/send_notification_form?type=" + notifType,
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        showLoader(false)
        setState({ ...state, ...response.data });

        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {

        }
      })
      .catch(function (response) { });
  };


  var handleSave1 = (e) => {
    showLoader1(true)
    var form = document.querySelector("#notificationbody");
    var data = new FormData(form);
    data.append('ids', selectedRowKeys.toString())
    if (watchservice && watchservice.value) {
      data.append('service', watchservice.value)
    }
    if (watch_task_created) {
      data.append('task_created', watch_task_created)
    }
    if (watch_due_type && watch_due_type.value) {
      data.append('due_type', watch_due_type.value)
    }
    instance({
      method: "post",
      url: "/send_bulk_notification?type=" + notifType,
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        showLoader1(false)
        //setState({ ...state, ...response.data });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          toast(response.data.msg, { type: "success" });
          // modal.hide();

          reset({})
          reset1({})
          setSelectedRowKeys([])
          showSteptwo(false)
          setType('task')
          fetchData();
        }
      })
      .catch(function (response) { });
  };


  const {
    register,
    handleSubmit,
    setError,
    watch,
    resetField,
    clearErrors,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    control: control1,
    watch: watch1,
    resetField: resetField1,
    formState: { errors: errors1 },
    reset: reset1,
  } = useForm({
    resolver: yupResolver(schema1),
  });


  const onSubmitHandler = async (data) => {
    handleSave(data);
  };
  const onSubmitHandler1 = async (data) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to stop!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, send it now!'
    }).then((result) => {
      if (result.isConfirmed) {

        handleSave1(data);
      }
    })

  };


  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };

  const userModal = useModal('select-template');

  const showTemplates = (row, row1, type) => {

    userModal.show({ data: row, active: row1, type: type }).then((res) => {
      if (type == 'sms') {
        setState({ ...state, sms_template: res.newid });
        resetField1("sms_template", { defaultValue: res.newid })
        resetField1("whatsapp_template", { defaultValue: null })
      } else {
        setState({ ...state, whatsapp_template: res.newid });
        resetField1("whatsapp_template", { defaultValue: res.newid })
        resetField1("sms_template", { defaultValue: null })
      }
    });

  }

  const fetchData = () => {
    instance
      .get("/send_notification_form?type=" + notifType)
      .then(function (response) {
        setState({ ...state, ...response.data });
        if (response.data.status == 'success') {


        } else {
          toast(response.data.msg, { type: "error" });
        }

      })
      .catch(function (error) { })
      .then(function () { });

  }

  useEffect(() => {
    fetchData();

    resetField('type', { defaultValue: notifType })
    resetField('cust_type', { defaultValue: '' })
    resetField('due_type', { defaultValue: '' })
    resetField1('sms_template', { defaultValue: '' })
    resetField1('whatsapp_template', { defaultValue: '' })

    setSelectedRowKeys([])

  }, [notifType]);

  useEffect(() => {
    if (state.email_body && state.email_subject) {
      reset1({ 'email_subject': state.email_subject, 'email_template': state.email_body })
    } else {
      reset1({})
    }

  }, [steptwo]);


  const location = useLocation();




  const watchservice = watch('service');
  const watchchannel = watch1('channel');
  const watch_task_created = watch('task_created');
  const watch_due_type = watch('due_type');





  return (
    <>
      <Helmet>
        <title>
          Send Notifications | Practive
        </title>
      </Helmet>
      <div>
        <PageHeader title="Send Notifications" >

        </PageHeader>
        <div className="card card-default">

          {steptwo == false &&
            <div className="tabs text-center d-flex">
              <span className={`tab-itm c-pointer flex-grow-1 ${notifType == 'task' && 'active'}`} onClick={() => setType('task')}>Task Notification</span>
              <span className={`tab-itm c-pointer flex-grow-1 ${notifType == 'payment_reminder' && 'active'}`} onClick={() => setType('payment_reminder')}>Payment Reminder</span>
              <span className={`tab-itm c-pointer flex-grow-1 ${notifType == 'custom' && 'active'}`} onClick={() => setType('custom')}>Custom Notification</span>

            </div>
          }
          <div className="card-body">

            {steptwo == true ? (
              <>
                <form id="notificationbody" noValidate>
                  <label className="form-label">Channel</label>
                  <div className="mb-3 mt-2">
                    <div className="d-flex">
                      {state.sms_templates != '' &&
                        <OverlayTrigger overlay={state.sms_allow == 1 ? <></> : <Tooltip>SMS balance is exhausted</Tooltip>}>
                          <div className="form-check mb-3 me-3">
                            <input type="radio" className="form-check-input" value='sms' disabled={state.sms_allow == 1 ? false : true} name="channel" id="channel1" {...register1("channel")} />
                            <label className="form-check-label" htmlFor="channel1">SMS</label>
                          </div>
                        </OverlayTrigger>
                      }
                      <OverlayTrigger overlay={state.wa_allow == 1 ? <></> : <Tooltip>WhatsApp API is not integrated</Tooltip>}>
                        <div className="form-check mb-3 me-3">
                          <input type="radio" className="form-check-input" value='whatsapp' disabled={state.wa_allow == 1 ? false : true} name="channel" id="channel2" {...register1("channel")} />
                          <label className="form-check-label" htmlFor="channel2">WhatsApp</label>
                        </div>
                      </OverlayTrigger>
                      <div className="form-check mb-3 me-3">
                        <input type="radio" className="form-check-input" value='email' name="channel" id="channel3" {...register1("channel")} />
                        <label className="form-check-label" htmlFor="channel3">Email</label>
                      </div>

                    </div>
                    {errors1.channel && (<div className="invalid-feedback d-block">{errors1.channel?.message}</div>)}
                  </div>
                  {watchchannel == 'sms' ?
                    <>
                      <div className="mb-4 ">
                        <label className="form-label fs-6 fw-bolder text-dark d-block">Template</label>


                        {state.sms_template && state.sms_template > 0 ? (
                          <>

                            <div className="p-2 border rounded">{state.sms_templates[state.sms_template].template}</div>

                            <a className="btn btn-sm btn-secondary mt-3" onClick={() => showTemplates(state.sms_templates, state.sms_template, 'sms')}>Change Template</a>
                          </>
                        ) : (
                          <>
                            <a className="btn btn-sm btn-secondary" onClick={() => showTemplates(state.sms_templates, '', 'sms')}>Select Template</a>

                          </>
                        )}
                        {errors1.sms_template && (<div className="invalid-feedback d-block">{errors1.sms_template?.message}</div>)}
                      </div>

                    </> : watchchannel == 'whatsapp' ? <>

                      <div className="mb-4 ">
                        <label className="form-label fs-6 fw-bolder text-dark d-block">Template</label>


                        {state.whatsapp_template && state.whatsapp_template > 0 ? (
                          <>

                            <div className="p-2 border rounded">{state.wa_templates[state.whatsapp_template].template}</div>

                            <a className="btn btn-sm btn-secondary mt-3" onClick={() => showTemplates(state.wa_templates, state.whatsapp_template, 'whatsapp')}>Change Template</a>
                            <div className="mt-3 mb-3">
                              <UiInput
                                label="Message Body Variables"
                                type="text"
                                parentClass="mb-1"
                                name="wa_vars"
                                message={errors1.wa_vars?.message}
                                {...register1("wa_vars")}
                              />
                              <small>Enter required variables in WhatsApp message template. (Use comma in case of multiple variables)<br></br></small>
                              <small>Example: {`{CLIENT_NAME}, {YOUR_COMPANY_NAME}`}</small>
                            </div>
                            {state.shortcodes && (
                              <div className="mb-3">
                                <b>Variables - </b>
                                <VariableCopy items={state.shortcodes} />

                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            <a className="btn btn-sm btn-secondary" onClick={() => showTemplates(state.wa_templates, '', 'whatsapp')}>Select Template</a>
                          </>
                        )}
                        {errors1.whatsapp_template && (<div className="invalid-feedback d-block">{errors1.whatsapp_template?.message}</div>)}
                      </div>

                    </> : watchchannel == 'email' ? <>
                      <small className="text-danger">Note: In case you are using custom SMTP, be aware of the daily sending limit. (Most providers allow up to 500 emails in 24 hours)</small>
                      <div className="mt-4">
                        <UiInput
                          label="Email Subject"
                          type="text"

                          name="email_subject"
                          message={errors1.email_subject?.message}
                          {...register1("email_subject")}
                        />
                        <Controller
                          name="email_template"
                          control={control1}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                          }) => (
                            <UiRichText

                              onChange={onChange}
                              value={value}
                              ref={ref}
                              label="Email Template"
                              name="email_template"
                              message={errors1.email_template?.message}
                            />
                          )}
                        />
                        {state.shortcodes && (
                          <div className="mb-3">
                            <b>Variables - </b>
                            <VariableCopy items={state.shortcodes} />

                          </div>
                        )}


                      </div>
                    </> : <></>}
                  <input type="hidden" name="sms_template" {...register1("sms_template")} />
                  <input type="hidden" name="whatsapp_template" {...register1("whatsapp_template")} />
                </form>
              </>
            ) : (
              <form id="notificationfilter" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
                {notifType == 'task' ?
                  <>

                    <div className="d-flex">
                      <span className="me-2">Show clients with created tasks only</span>
                      <UiToggle
                        id="task_created"
                        name="task_created"
                        {...register("task_created")}
                      />
                    </div>

                    <Controller
                      name="service"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect
                          {...field}
                          lcol="3"
                          icol="9"
                          required={true}
                          options={state.services}
                          className=""
                          message={errors.service?.message}
                          label="Service"
                        />

                      )}
                    />

                    {watch_task_created == true &&
                      <>
                        {watchservice && watchservice.freq == 'Yearly' && (



                          <UiSelect
                            label="Select Year"
                            lcol="3"
                            icol="9"
                            required={true}
                            options={state.years}
                            //defaultValue={val.value}
                            name="fyear"
                            message={errors.fyear?.message}
                            {...register('fyear')}
                          />


                        )}

                        {watchservice && watchservice.freq == 'Quarterly' && (
                          <>


                            <UiSelect
                              lcol="3"
                              icol="9"
                              required={true}
                              label="Select Quarter"
                              options={['Apr-Jun', 'Jul-Sep', 'Oct-Dec', 'Jan-Mar']}
                              //defaultValue={val.value}
                              name="quarter"
                              message={errors.quarter?.message}
                              {...register('quarter')}
                            />

                            <Controller
                              name="year"
                              control={control}

                              render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { invalid, isTouched, isDirty, error },
                                formState,
                              }) => (
                                <UiDatePicker
                                  label="Select Year"
                                  onChange={onChange}
                                  required={true}
                                  lcol="3"
                                  icol="9"
                                  default_val={value}
                                  message={errors.year?.message}
                                  dateFormat="yyyy"
                                  showYearPicker
                                  // showQuarterYearPicker
                                  ref={ref}
                                  name={name}
                                />
                              )}
                            />

                          </>
                        )}


                        {watchservice && watchservice.freq == 'Half-Yearly' && (
                          <>


                            <UiSelect
                              lcol="3"
                              icol="9"
                              required={true}
                              label="Select Period"
                              options={['Apr-Sep', 'Oct-Mar']}
                              //defaultValue={val.value}
                              name="hy_period"
                              message={errors.hy_period?.message}
                              {...register('hy_period')}
                            />

                            <Controller
                              name="year"
                              control={control}

                              render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { invalid, isTouched, isDirty, error },
                                formState,
                              }) => (
                                <UiDatePicker
                                  label="Select Year"
                                  onChange={onChange}
                                  required={true}
                                  lcol="3"
                                  icol="9"
                                  default_val={value}
                                  message={errors.year?.message}
                                  dateFormat="yyyy"
                                  showYearPicker
                                  // showQuarterYearPicker
                                  ref={ref}
                                  name={name}
                                />
                              )}
                            />

                          </>
                        )}

                        {watchservice && watchservice.freq == 'Monthly' && (


                          <Controller
                            name="month_year"

                            control={control}
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                              fieldState: { invalid, isTouched, isDirty, error },
                              formState,
                            }) => (
                              <UiDatePicker
                                label="Select Month & Year"
                                onChange={onChange}
                                required={true}
                                default_val={value}
                                lcol="3"
                                icol="9"
                                message={errors.month_year?.message}
                                dateFormat="MMM - yyyy"
                                showMonthYearPicker
                                // showQuarterYearPicker
                                ref={ref}
                                name={name}
                              />
                            )}
                          />

                        )}

                        <Controller
                          name="status[]"
                          control={control}
                          render={({ field }) => (

                            <UiRSelect
                              {...field}
                              options={[{ 'value': 'Pending', 'label': 'Pending' }, { 'value': 'Hold', 'label': 'Hold' }, { 'value': 'In-Progress', 'label': 'In-Progress' }]}
                              className=""
                              required={true}
                              lcol="3"
                              icol="9"
                              isMulti
                              message={errors.status?.message}
                              label="Status"
                            />

                          )}
                        />

                      </>}

                  </>
                  : notifType == 'payment_reminder' ? <>
                    <Controller
                      name="due_type"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect
                          {...field}
                          options={state.options}
                          className=""
                          required={true}
                          lcol="3"
                          icol="9"
                          message={errors.due_type?.message}
                          label="Clients having"
                        />

                      )}
                    />
                  </> : notifType == 'custom' ?
                    <>
                      <Controller
                        name="cust_type"
                        control={control}
                        render={({ field }) => (

                          <UiRSelect
                            {...field}
                            options={state.cust_types}
                            className=""
                            required={true}
                            lcol="3"
                            icol="9"
                            message={errors.cust_type?.message}
                            label="Type of client"
                          />

                        )}
                      />

                    </> : <>
                    </>
                }
                <input
                  type="hidden"
                  name="type"
                  {...register("type")}
                />
                <div className="text-center mb-3">
                  <UiButton className="btn btn-secondary" loading={loader} title="Load Clients" />
                </div>

                <Table
                  locale={{
                    emptyText: (<TableZero title="Clients" />)
                  }}
                  pagination={false}
                  virtual={true}
                  size="small"
                  columns={state.columns}
                  rowKey={(record) => record.id}
                  dataSource={state.items}
                  //loading={{ indicator: <BgLoader /> }}
                  rowSelection={rowSelection}
                  scroll={{
                    x: 450,

                  }}
                />
              </form>
            )}
            <div className="mt-3">
              {steptwo == true ?
                <>
                  <UiButton className="btn btn-secondary me-2" onClick={() => showSteptwo(false)} title="Back" />
                  <UiButton onClick={handleSubmit1(onSubmitHandler1)} loading={loader1} title="Send" />
                </>
                :
                <UiButton onClick={() => showSteptwo(true)} disabled={selectedRowKeys.length > 0 ? false : true} title="Next" />
              }
            </div>

          </div>
        </div>
      </div>
    </>
  );
}
