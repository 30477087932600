import React, { forwardRef, useRef, useState, useEffect, Component } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select, { StylesConfig } from "react-select";
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import { AiOutlineSearch, AiOutlineMinus, AiOutlineFileSearch, AiOutlinePlus, AiOutlineUserAdd } from "react-icons/ai";
import { MdOutlineClose, MdArrowForwardIos, MdOutlineCalendarToday, MdOutlineRefresh, MdArrowForward, MdUpdate, MdCheck, MdOutlineMoreVert, MdContentCopy, MdAdd } from "react-icons/md";
import DatePicker, { registerLocale } from "react-datepicker";
import { instance } from "./axios";
import { format, isValid, getMonth, getYear, parse, startOfToday, differenceInCalendarDays, startOfYesterday, subDays, startOfMonth, endOfMonth } from 'date-fns';
import Dropdown from 'react-bootstrap/Dropdown';
import "./react-datepicker.css";
import ReactQuill from 'react-quill';
import { GoPlus, GoDotFill } from "react-icons/go";
import AsyncSelect from 'react-select/async';
import { Link, useLocation, useNavigate } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { BsArrowRightCircle } from 'react-icons/bs'
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useDropzone } from 'react-dropzone';
import { HiOutlineRefresh } from 'react-icons/hi'
import { IoInformationCircleOutline } from 'react-icons/io5'
import { ConfigProvider, TimePicker } from 'antd';
import { BsImageFill, BsFillGearFill } from "react-icons/bs";
import { MdEdit, MdClose, MdOutlineDelete, MdAccessTime, MdOutlineKeyboardArrowDown, MdOutlineArrowBack } from "react-icons/md";
import enIN from 'date-fns/locale/en-IN';
import dayjs from 'dayjs';
import { IoIosArrowDown, IoMdAdd } from 'react-icons/io'
import 'react-quill/dist/quill.snow.css';
import { calcLength } from "framer-motion";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { BsFillExclamationTriangleFill } from 'react-icons/bs'
import formatRelative from 'date-fns/formatRelative'
import { BiShow, BiHide } from 'react-icons/bi'
import enGB from 'date-fns/locale/en-GB';
import { useQuery, useQueryClient, select } from "react-query";
import { FixedSizeList as List } from "react-window";
import { Select as Select1, Avatar as Avatar1, Tooltip as Tooltip1, Popover } from 'antd';
import { useStore } from "./state.js";
import Overflow from 'rc-overflow';

const { Option } = Select1;




const formatRelativeLocale = {
  lastWeek: 'dd-MM-yyyy hh:mm a',
  yesterday: "'Yesterday at ' hh:mm a",
  today: "'Today at' hh:mm a",
  tomorrow: "'Tomorrow at ' hh:mm a",
  nextWeek: 'dd-MM-yyyy hh:mm a',
  other: 'dd-MM-yyyy hh:mm a',
};

const locale = {
  ...enGB,
  formatRelative: (token) => formatRelativeLocale[token],
};

const CustomOption = ({ children, ...props }) => {

  return (
    <components.Option {...props}>

      {children}
      {props.data.secondary_text &&
        <small className="d-block">{props.data.secondary_text}</small>
      }
    </components.Option>
  );
};
const height = 35;
class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;
    console.log(children.props)
    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}
const CustomOption1 = ({ children, ...props }) => {
  console.log(children)
  //const [value] = getValue();
  const initialOffset = 100;
  return (

    <components.Option {...props}>

      {children}
      {props.data.secondary_text &&
        <small className="d-block">{props.data.secondary_text}</small>
      }

    </components.Option>

  );
};
const rselectstyle = (e) => ({
  ...e,
  control: (styles, state) => ({

    ...styles,
    fontSize: '0.9rem',
    height: '38px',
    minHeight: '38px',
    borderColor: state.isFocused ? "var(--bs-blue)" : "var(--bs-gray-200)",
    boxShadow: state.isFocused ? "none" : "1px",
    borderRadius: "var(--bs-border-radius-lg)",
    ':hover': {
      borderColor: state.isFocused ? "var(--bs-blue)" : "var(--bs-gray-200)",

    },


  }),
  menu: provided => ({ ...provided, zIndex: 2 })

})
export function UiModal(props) {
  const { show, onHide, heading, ...other } = props;
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body {...other} />
    </Modal>
  );
}

export const UiInput = forwardRef(
  (
    {
      type,
      name,
      parentClass,
      noerror,
      label,
      required,
      placeholder,
      className,
      max_width,
      lcol,
      icol,
      icon,
      message,
      prefix,
      showicon,
      footer_text,
      ...props
    },
    ref
  ) => {
    const [showpass, setShowpass] = useState(false);
    return (
      <div className={`${parentClass ? parentClass : "mb-4"} ${lcol ? "row" : ""}`}>
        {label && (
          <label
            className={`form-label ${lcol ? "pt-sm-2 col-sm-" + lcol : ""
              }`}
          >
            {label}{" "}
            <span className="text-red font-bold text-lg">
              {required && "*"}
            </span>
          </label>
        )}

        <div className={`${icol && "col-sm-" + icol}`} style={max_width && { maxWidth: max_width }}>
          <div className={`position-relative ${prefix && 'input-group'}`}>
            {prefix && (<span className="input-group-text">{prefix}</span>)}
            {icon == 'inr' && <div className="fieldicon">
              ₹
            </div>}
            {icon == 'percent' && <div className="fieldicon">
              %
            </div>}

            <input
              name={name}
              ref={ref}
              type={showpass == true ? "text" : type}
              className={`form-control form-control-solid ${message ? "is-invalid" : ""} ${icon && 'input_i'} ${showicon && 'input_suf'} ${className}`}
              placeholder={placeholder}
              autoComplete="off"
              {...props}
            />
            {showicon == 1 && <div className="fieldsufx" >
              {showpass == true ? <span className="c-pointer sicon" onClick={() => setShowpass(false)}><BiShow /></span> : <span className="c-pointer sicon" onClick={() => setShowpass(true)}><BiHide /></span>}

            </div>}
            {footer_text &&
              <div className="form-text">{footer_text}</div>
            }
            {!noerror && message && <div className="invalid-feedback">{message}</div>}
          </div>
        </div>
      </div>
    );
  }
);

export const UiButton = forwardRef(
  ({ type, name, title, className, icon, loading, disabled, hideloadmsg, ...props }, ref) => {
    return (
      <>
        <button
          name={name}
          ref={ref}
          type={type}
          className={`btn btn-primary ${icon && 'd-inline-flex'} ${className}`}
          {...props}
          disabled={((loading) || (disabled == 1)) ? "disabled" : ""}
        >
          {loading ? (
            <span className="indicator-progress">
              {hideloadmsg ? '' : 'Please wait...  '}
              <span className="spinner-border spinner-border-sm align-middle"></span>
            </span>
          ) : (
            <>
              {icon == 'add' && <span className="sicon"><MdAdd /></span>}
              {icon == 'go' && <span className="sicon"><BsArrowRightCircle /></span>}
              {icon == 'edit' && <span className="sicon"><MdEdit /></span>}
              {icon == 'delete' && <span className="sicon"><MdOutlineDelete /></span>}
              {title && <span className={icon && 'ms-2'} >{title}</span>}
            </>
          )}
        </button>
      </>
    );
  }
);

export const UiToggle = forwardRef(({ id, name, pclass, loading, title, ...props }, ref) => {
  return (
    <>
      {loading ? (
        <div className="ms-2">
          <span className="spinner-border spinner-border-sm "></span>
        </div>
      ) : (

        <div className={`form-check form-switch ${pclass ? '' : 'mb-4'}`}>
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            name={name}
            ref={ref}
            id={id}
            {...props}
          />
          <label className="form-check-label" htmlFor={id}>
            {title}
          </label>
        </div>

      )}
    </>
  );
});

export const UiModal1 = (props) => {
  const { kind, ...other } = props;
  const className = kind === "primary" ? "PrimaryButton" : "SecondaryButton";
  return <b className={className} {...other} />;
};



export const UiRSelect = forwardRef(
  (
    {

      label,
      required,
      placeholder,
      footer_text,
      className,
      lcol,
      icol,
      styles,
      message,
      addnew,
      handleNew,
      ...props
    },
    ref
  ) => {
    const MenuList = (
      props
    ) => {
      return (
        <components.MenuList {...props}>

          {props.children}
          <div className="p-2">
            <UiButton className="btn btn-secondary align-self-center w-100" onClick={() => handleNew()} icon="add" title="Add New"></UiButton>
          </div>
        </components.MenuList>
      );
    };
    return (

      <div className={`mb-4 ${lcol ? "row" : ""}`}>
        {label &&
          <label
            className={`form-label ${lcol ? "pt-sm-2 col-sm-" + lcol : ""
              }`}
          >
            {label}{" "}
            <span className="text-red font-bold text-lg">
              {required && "*"}
            </span>
          </label>
        }
        <div className={icol ? "col-sm-" + icol : ""}>
          <Select

            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: 'rgba(var(--bs-primary-rgb), 0.25)',
                primary: 'var(--bs-blue)',
                neutral5: 'var(--bs-gray-200)',
              },
            })}

            styles={rselectstyle(styles)}
            classNamePrefix="rselect"
            ref={ref}
            components={addnew && { MenuList }}
            className={`rselect ${message ? "is-invalid" : ""} ${className}`}
            hideSelectedOptions={false}
            {...props}
          />
          {message && <div className="invalid-feedback">{message}</div>}
          {footer_text &&
            <div className="form-text">{footer_text}</div>
          }
        </div>
      </div>
    );
  }
);



export const UiTagInput = forwardRef(
  (
    {

      label,
      required,
      placeholder,
      className,
      lcol,
      icol,
      styles,
      message,
      ...props
    },
    ref
  ) => {
    return (
      <div className={`mb-4 ${lcol ? "row" : ""}`}>
        <label
          className={`form-label ${lcol ? "pt-sm-2 col-sm-" + lcol : ""
            }`}
        >
          {label}{" "}
          <span className="text-red font-bold text-lg">
            {required && "*"}
          </span>
        </label>
        <div className={icol ? "col-sm-" + icol : ""}>
          <CreatableSelect
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: 'rgba(var(--bs-primary-rgb), 0.25)',
                primary: 'var(--bs-blue)',
              },
            })}

            classNamePrefix="rselect"
            isClearable
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            isMulti
            noOptionsMessage={() => null}
            ref={ref}

            className={`rselect ${message ? "is-invalid" : ""
              } ${className}`}
            styles={rselectstyle(styles)}
            {...props}


          />
          {message && <div className="invalid-feedback">{message}</div>}
        </div>
      </div>
    );
  }
);


export const UiSelect = forwardRef(({ icol, placeholder, footer_text, className, message, noBlank, parentClass, lcol, options, label, required, default_val, name, noerror, title, ...props }, ref) => {
  return (
    <div className={`${parentClass ? parentClass : "mb-4"} ${lcol ? "row" : ""}`}>
      {label && (
        <label
          className={`form-label ${lcol ? "pt-sm-2 col-sm-" + lcol : ""
            }`}
        >
          {label}{" "}
          <span className="text-red font-bold text-lg">
            {required && "*"}
          </span>
        </label>
      )}
      <div className={icol ? "col-sm-" + icol : ""}>
        <select

          className={`form-select ${message ? "is-invalid" : ""
            } ${className}`}
          name={name}
          ref={ref}
          {...props}>
          {noBlank !== true && <option value='' >{placeholder && placeholder}</option>}
          {options[0].value ? options.map((option, i) => (
            <option value={option.value} key={i}>{option.label}</option>
          )) :
            options.map((option, i) => (
              <option value={option} key={i}>{option}</option>
            ))
          }
        </select>
        {!noerror && message && <div className="invalid-feedback">{message}</div>}
        {footer_text &&
          <div className="form-text">{footer_text}</div>
        }
      </div>
    </div>
  );
});


export function UiTFind(props) {
  const { setFilterText, filterText, heading } = props;
  return (
    <div className="input-group flex-nowrap width25">
      <span className="input-group-text" ><AiOutlineSearch /></span>
      {filterText && (<span className="clearbtn" onClick={() => setFilterText('')}><MdOutlineClose /></span>)}
      <input className="form-control" onChange={e => setFilterText(e.target.value)} value={filterText} />

    </div>
  );
}



export function UiStatusChange(props) {
  const { status, onUpdate, loading, disabled, sts } = props;
  return (
    <DropdownMenu
      trigger={({ triggerRef, ...props }) => (
        <button type="button" className={`btn  ${status == 'Pending' ? 'btn-outline-warning' : (status == 'Completed' || status == 'Accepted') ? 'btn-outline-success' : (status == 'Hold' || status == 'Rejected') ? 'btn-outline-danger' : 'btn-outline-primary'}`} disabled={disabled} {...props}
          ref={triggerRef} >{loading == true ? <span className="indicator-progress"  >
            Please wait...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span> : <>{status}<span className="ms-2"><IoIosArrowDown /></span></>}</button>

      )}
    >
      <DropdownItemGroup>

        {sts.map((val, i) => (
          <DropdownItem key={i} isDisabled={(val == status || loading == true) ? true : false} onClick={() => onUpdate(val)}>{val}</DropdownItem>
        ))}
      </DropdownItemGroup>
    </DropdownMenu>



  );
}

const yearto = getYear(new Date()) + 10;
const years = Array(yearto - (yearto - 80)).fill('').map((v, idx) => yearto - idx);

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const ExampleCustomInput = forwardRef((props, ref) => (
  <>
    <div className="fieldicon">
      <MdOutlineCalendarToday />
    </div>
    <input ref={ref} {...props} />
  </>
)
)

export const UiDatePicker = forwardRef(({ icol, className, dateFormat, message, lcol, label, required, default_val, placeholder, name, onChange, title, ...props }, ref) => {

  const inputRef = useRef(null);

  const [startDate, setStartDate] = useState();
  const handleChangeRaw = (date) => {
    console.log('s')
    const newRaw = new Date(date.currentTarget.value);
    if (newRaw instanceof Date && !isNaN(newRaw) && isValid(newRaw)) {

      onChange(format(newRaw, dateFormat));
    }
  };


  const onChangehandler = (e) => {
    console.log(e)
    if (e != 'Invalid Date') {
      //console.log('a')
      onChange(format(e, dateFormat))
    }

  }


  useEffect(() => {
    //console.log(startDate)
    if (isValid(startDate)) {
      onChange(format(startDate, dateFormat))
    } else {
      onChange('')
    }
  }, [startDate]);


  useEffect(() => {

    if (default_val) {

      setStartDate(parse(default_val.replace('/', '-'), dateFormat, new Date()))
    }
    if (default_val == null) {
      setStartDate(null)
    }
  }, [default_val]);



  return (
    <div className={`mb-4 ${lcol ? "row" : ""}`}>
      {label &&
        <label className={`form-label  ${lcol ? "pt-sm-2 col-sm-" + lcol : ""}`}>
          {label}{" "}
          <span className="text-red font-bold text-lg">{required && "*"}</span>
        </label>
      }
      <div className={`${icol ? "col-sm-" + icol : ""}`}>

        <DatePicker
          className={`form-control ${message ? "is-invalid" : ""
            } ${className} input_i`}
          //onChange={(e) => onChangehandler(e)} // send value to hook form
          //selected={default_val && parse(default_val, dateFormat, new Date())}
          popperPlacement="bottom-start"
          showPopperArrow={false}
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          name={name}
          //dateFormat={dateFormat}
          dateFormat={dateFormat == 'dd-MM-yyyy' ? ['dd-MM-yyyy', 'dd/MM/yyyy'] : dateFormat}
          customInput={<ExampleCustomInput />}
          placeholderText={placeholder}
          ref={ref}
          {...props}
        //onChangeRaw={(e) => handleChangeRaw(e)}

        />
        {message && <div className="invalid-feedback d-block">{message}</div>}

      </div>
    </div>
  );
});


export const UiRichText = forwardRef(
  (
    {
      type,
      name,
      parentClass,
      noerror,
      label,
      required,
      placeholder,
      className,
      lcol,
      icol,
      message,
      ...props
    },
    ref
  ) => {
    const [state, setState] = useState('');

    const handleOnChange = (e) => {
      if (e != '<p><br></p>') {
        props.onChange(e);
        //setState(e);
      } else {
        // setState('');
        props.onChange('');
      }
    }

    useEffect(() => {
      if (props.value) {
        setState(props.value)
      } else {
        setState('')
      }
    }, [props.value]);


    return (
      <div className={`${parentClass ? { parentClass } : "mb-4"} ${lcol ? "row" : ""}`}>
        {label && (
          <label
            className={`form-label ${lcol ? "pt-sm-2 col-sm-" + lcol : ""
              }`}
          >
            {label}{" "}
            <span className="text-red font-bold text-lg">
              {required && "*"}
            </span>
          </label>
        )}
        <div className={icol ? "col-sm-" + icol : ""}>
          <ReactQuill theme="snow"
            ref={ref} className={`${message ? "is-invalid" : ""
              } ${className}`} value={props.value} onChange={(e) => handleOnChange(e)} />
          <input type="hidden" name={name} value={state} onChange={setState} />
          {!noerror && message && <div className="invalid-feedback">{message}</div>}
        </div>
      </div>
    );
  }
);



export const UiTextArea = forwardRef(
  (
    {
      type,
      name,
      parentClass,
      noerror,
      label,
      required,
      placeholder,
      className,
      lcol,
      icol,
      message,
      ...props
    },
    ref
  ) => {
    return (
      <div className={`${parentClass ? { parentClass } : "mb-4"} ${lcol ? "row" : ""}`}>
        {label && (
          <label
            className={`form-label ${lcol ? "pt-sm-2 col-sm-" + lcol : ""
              }`}
          >
            {label}{" "}
            <span className="text-red font-bold text-lg">
              {required && "*"}
            </span>
          </label>
        )}
        <div className={icol ? "col-sm-" + icol : ""}>
          <textarea
            name={name}
            ref={ref}
            type={type}
            className={`form-control ${message ? "is-invalid" : ""
              } ${className}`}
            placeholder={placeholder}
            autoComplete="off"
            {...props}
          />
          {!noerror && message && <div className="invalid-feedback">{message}</div>}
        </div>
      </div>
    );
  }
);



export const UiImageUpload = forwardRef(
  (
    {
      file,
      name,
      ratio,
      parentClass,
      noerror,
      label,
      required,
      placeholder,
      max_width,
      className,
      lcol,
      icol,
      setFile,
      width,
      height,
      message,
      onClear,
      ...props
    },
    ref
  ) => {
    const [files, setFiles] = useState([]);
    const [editfile, setEditFile] = useState([]);
    const [state, setState] = useState([]);

    const { getRootProps, getInputProps, open } = useDropzone({

      accept: {
        'image/*': []
      },
      onDrop: acceptedFiles => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        })));

      }
    });
    useEffect(() => {
      // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
      return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    const userModal = useModal('crop');

    const showAddModal = (row) => {
      userModal.show({ data: row, width1: width, height1: height }).then((res) => {
        setState({ ...state, loader: true })

        var toblob = new File([res.cropped.blob1], 'a.jpg', { type: "image/jpeg" });

        var bodyFormData = new FormData();
        // bodyFormData.append('data', res.cropped.data);
        bodyFormData.append("file", toblob);


        instance({
          method: "post",
          url: "/image_upload",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then(function (response) {
            setState({ ...state, loader: false })
            if (response.data.status == "success") {
              setEditFile(response.data.file)
              setFile(response.data.file)
            }
          })
          .catch(function (response) { });
      });



    }

    const handleClear = () => {
      onClear(null)
      setEditFile(null)
    }


    useEffect(() => {
      if (files && files.length > 0) {
        showAddModal(files[0].preview, width, height);
      }
    }, [files]);

    useEffect(() => {

      setEditFile(file)
    }, [file]);


    return (
      <div className={`${parentClass ? { parentClass } : "mb-4"} ${lcol ? "row" : ""}`}>
        {label && (
          <label
            className={`form-label ${lcol ? "pt-sm-2 col-sm-" + lcol : ""
              }`}
          >
            {label}{" "}
            <span className="text-red font-bold text-lg">
              {required && "*"}
            </span>
          </label>
        )}
        <div className={icol ? "col-sm-" + icol : ""}>
          <div className="position-relative" style={{ maxWidth: max_width ? max_width : '300px' }}>
            <div className={`p-2 border rounded bg-light ratio ${ratio}`}>
              <input {...getInputProps()} />
              {state.loader && <InLoader />}
              {file ? <img className="rounded" src={file} /> : (<div className="placeholderimg"><BsImageFill /></div>)}

            </div>
            <div className="text-center">
              <button type="button" className="btn btn-secondary imagebtn" onClick={open}><MdEdit /></button>
              {file && <div className="btn btn-secondary imagebtn" onClick={handleClear}><MdClose /></div>}
            </div>
          </div>
          {!noerror && message && <div className="invalid-feedback">{message}</div>}
        </div>
        {editfile && <input type="hidden" value={editfile} name={name} />}
      </div>
    );
  }
);



export const UiFindSelect = forwardRef(
  (
    {

      label,
      required,
      placeholder,
      loadOptions,
      className,
      lcol,
      parentClass,
      icol,
      message,
      ...props
    },
    ref
  ) => {





    return (
      <div className={`${parentClass ? parentClass : "mb-4"} ${lcol ? "row" : ""}`}>
        <label
          className={`form-label ${lcol ? "pt-sm-2 col-sm-" + lcol : ""
            }`}
        >
          {label}{" "}
          <span className="text-red font-bold text-lg">
            {required && "*"}
          </span>
        </label>
        <div className={icol ? "col-sm-" + icol : ""}>
          <AsyncSelect
            //cacheOptions 


            noOptionsMessage={() => 'Type to search'}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: 'rgba(var(--bs-primary-rgb), 0.25)',
                primary: 'var(--bs-blue)',
              },
            })}
            styles={rselectstyle()}
            classNamePrefix="rselect"
            loadOptions={loadOptions}
            isClearable
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, Option: CustomOption }}
            ref={ref}


            className={`rselect ${message ? "is-invalid" : ""
              } ${className}`}

            {...props}


          />
          {message && <div className="invalid-feedback">{message}</div>}
        </div>
      </div>
    );
  }
);


export const BgLoader = forwardRef(
  (
    {
      size,
      ...props
    },
    ref
  ) => {
    return (
      <div className="d-flex align-items-center w-100 justify-content-center bgloader" style={size == 'small' ? { height: '50px' } : { height: '200px' }} >
        <div className="loader text-primary" style={size == 'small' ? { fontSize: '5px', marginTop: '5px', marginBottom: '0px' } : { fontSize: '10px' }}>Loading...</div>
      </div>
    );
  }
);

export const InLoader = forwardRef(
  (
    {
      ...props
    },
    ref
  ) => {
    return (
      <div className="d-flex align-items-center position-absolute skeleton justify-content-center w-100 h-100" style={{ backgroundColor: '#ffffffa8', zIndex: 99 }} >
        <div className="loader1 text-primary"></div>
      </div>
    );
  }
);

export const OverlayLoader = forwardRef(
  (
    {
      visible,
      ...props
    },
    ref
  ) => {
    return (
      <div className={`overlayloader ${visible == true ? '' : 'd-none'}`} >
        <span className="spinner-border spinner-border-sm align-middle ms-2 text-primary" style={{ width: '3rem', height: '3rem' }}></span>
      </div>
    );
  }
);

export const TableZero = forwardRef(
  (
    {
      title,
      ...props
    },
    ref
  ) => {
    return (
      <div className="mb-3 text-center text-muted" >
        <span className="fs-1 d-block text-muted"><AiOutlineFileSearch /></span>
        There are no {title}
      </div>
    );
  }
);


export function PageHeader(props) {
  const location = useLocation();
  let navigate = useNavigate();
  const { link, parent, stacked, noBack, title, ...other } = props;
  const doesAnyHistoryEntryExist = location.key !== "default";


  const handleBack = () => {
    if (doesAnyHistoryEntryExist == true) {
      navigate(-1)
    } else {
      navigate("/")
    }
  }
  return (
    <div className={`${stacked == true ? 'd-lg-flex align-items-center' : 'd-flex align-items-center'} pageheader`}>
      <div className={`${stacked == true ? 'flex-grow-1' : 'flex-grow-1'}`}><h1 className="d-flex align-items-sm-center flex-sm-row flex-column">
        <div className="d-flex parentpd">{!noBack && <span className="sicon me-2 c-pointer" onClick={() => handleBack()}><MdOutlineArrowBack /></span>}{parent && (<><Link className="text-decoration-none" to={link}>{parent}</Link> <span className="sicon mx-1 d-none d-sm-flex"><MdArrowForwardIos /></span></>)} </div><span>{title}</span></h1></div>

      <div className="d-inline-flex" {...other} />
    </div>
  );
}


export const UiDateRange = forwardRef(
  (
    {
      title,
      size = 'default',
      parentClass,
      value,
      onUpdate
    },
    ref
  ) => {

    const [show, setShow] = useState(false);
    const [state, setState] = useState({ title: 'All Time' });

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const onChange = (dates) => {
      const [start, end] = dates;

      setStartDate(start);
      setEndDate(end);
    };

    const applyTime = () => {

      var start = differenceInCalendarDays(startDate, startOfToday())
      var end = differenceInCalendarDays(endDate, startOfToday())
      var selected;
      var title;
      if (start == 0 && end == 0) {
        selected = 'Today'
        title = selected
      } else if (start == -1 && end == -1) {
        selected = 'Yesterday'
        title = selected
      } else if (start == -6 && end == 0) {
        selected = 'Last 7 Days'
        title = selected
      } else if (start == -29 && end == 0) {
        selected = 'Last 30 Days'
        title = selected
      } else {
        selected = 'Custom'
        title = format(startDate, 'dd-MM-yyyy') + ' - ' + format(endDate, 'dd-MM-yyyy')
      }

      setState({ ...state, time: selected, title: title })
      onUpdate(title)
      setShow(false)

    }

    const selectOption = (val) => {

      if (val !== 'Custom') {
        setShow(false)
        setState({ ...state, title: val, time: val })
        onUpdate(val)
      } else {
        setState({ ...state, time: val })
      }
    }
    var options = ['Today', 'Yesterday', 'Last 7 Days', 'Last 30 Days', 'This Month', 'Last Month', 'All Time', 'Custom']
    if (size != 'default') {
      options = ['Today', 'Yesterday', 'Last 7 Days', 'Last 30 Days', 'This Month', 'Last Month', 'All Time']
    }
    useEffect(() => {
      if (options.indexOf(state.title) === -1) {
        setState({ ...state, time: 'Custom' })
        const myArray = state.title.split(" - ");
        if (myArray.length == 2) {
          setStartDate(parse(myArray[0], 'dd-MM-yyyy', new Date()))
          setEndDate(parse(myArray[1], 'dd-MM-yyyy', new Date()))
        }
      } else {
        setState({ ...state, time: state.title })
      }

      if (state.title == 'Yesterday') {
        setStartDate(startOfYesterday())
        setEndDate(startOfYesterday())
      } else if (state.title == 'Last 7 Days') {
        setStartDate(subDays(new Date(), 6))
        setEndDate(new Date())
      } else if (state.title == 'Last 30 Days') {
        setStartDate(subDays(new Date(), 29))
        setEndDate(new Date())
      } else if (state.title == 'This Month') {
        setStartDate(startOfMonth(new Date()))
        setEndDate(new Date())
      } else if (state.title == 'Today') {
        setStartDate(new Date())
        setEndDate(new Date())
      } else if (state.title == 'Last Month') {
        var x = new Date();
        x.setDate(1);
        x.setMonth(x.getMonth() - 1);
        setStartDate(startOfMonth(x))
        setEndDate(endOfMonth(x))
      } else if (state.title == 'All Time') {

        setStartDate()
        setEndDate()
      }

    }, [show]);

    useEffect(() => {
      if (value == '' || value == null) {
        //  setState({ ...state, title: 'All Time' })
        onUpdate('All Time')
      } else {
        setState({ ...state, title: value })
      }



    }, [value]);



    return (
      <Dropdown autoClose='outside' show={show} onToggle={(isOpen) => setShow(isOpen)} className={`${parentClass ? parentClass : 'mb-4'}`}>
        <Dropdown.Toggle className={`daterangebtn  w-100 input_i position-relative ${size == 'small' ? 'btn-sm daterangebtn-sm' : size == 'xs' ? 'daterangebtn-xs' : 'border bg-white'}`} variant="default" id="dropdown-basic1"  >
          <div className="fieldicon"><MdOutlineCalendarToday /></div> {size != 'xs' ? state.title : ''}
        </Dropdown.Toggle>

        <Dropdown.Menu className="py-0 overflow-hidden shadow" >
          <div className="d-md-flex">
            <div className={`p-2 mb-light`} style={{ minWidth: '150px' }}>
              <p className={`fw-semibold ${size == 'default' ? '' : 'd-none'}`}>Date Presets</p>
              {options.map((val, i) => (
                <button type="button" key={i} className={`d-block btn btn-sm  ${state.time == val && 'btn-outline-primary'}`} onClick={() => selectOption(val)}>{val}</button>
              ))}



            </div>

            <div className={`col dtpicker_calendar ${size == 'default' ? '' : 'd-none'}`} >
              <div className="px-3 py-2">

                <div className="mb-2 small text-center text-muted">
                  <span >
                    {startDate && (format(startDate, 'dd-MM-yyyy'))}
                  </span>  −  <span >
                    {endDate && (format(endDate, 'dd-MM-yyyy'))}
                  </span>
                </div>
                <DatePicker
                  disabledKeyboardNavigation
                  selected={startDate}

                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  inline
                />
                <div className="text-end">
                  <button onClick={() => applyTime()} className="btn btn-outline-primary btn-sm" disabled={startDate && endDate ? '' : true}>Apply</button>

                </div>
              </div>

            </div>

          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
);





export const UiPeriodSelect = forwardRef(
  (
    {
      title,
      parentClass,
      size = 'default',
      value,
      onUpdate
    },
    ref
  ) => {

    const [show, setShow] = useState(false);
    const [state, setState] = useState({ title: '', value: 'All Time' });

    const [selectedDate, setDate] = useState(null);
    const onChange = (date) => {

      setDate(date);

    };

    const applyTime = () => {

      var label;
      const year = selectedDate.getFullYear();
      const month = selectedDate.getMonth();
      const monthName = selectedDate.toLocaleString('default', { month: 'short' }).slice(0, 3);

      if (state.title == 'Monthly') {
        label = `${monthName} - ${year}`
      } else if (state.title == 'Quarterly') {
        if (month >= 0 && month <= 2) {
          label = "Jan-Mar - " + year;
        } else if (month >= 3 && month <= 5) {
          label = "Apr-Jun - " + year;
        } else if (month >= 6 && month <= 8) {
          label = "Jul-Sep - " + year;
        } else if (month >= 9 && month <= 11) {
          label = "Oct-Dec - " + year;
        }

      } else if (state.title == 'Yearly') {
        label = `${year}-${(year + 1).toString().slice(-2)}`;
      }

      setState({ ...state, value: label });


      onUpdate(label)

      setShow(false)
    }

    const selectOption = (val) => {
      if (val !== 'All Time') {
        setState({ ...state, title: val })
      } else {
        setState({ ...state, title: val, value: val })
        setShow(false)
        onUpdate(val)
      }
    }
    const options = ['Monthly', 'Quarterly', 'Yearly', 'All Time']
    useEffect(() => {
      if (value == '' || value == null) {

      } else {
        const trimmedString = value.trim();
        if (trimmedString.length == 10) {
          const [monthStr, yearStr] = value.split(' - ');

          const months = {
            'Jan': 0, 'Feb': 1, 'Mar': 2, 'Apr': 3, 'May': 4, 'Jun': 5,
            'Jul': 6, 'Aug': 7, 'Sep': 8, 'Oct': 9, 'Nov': 10, 'Dec': 11
          };

          const month = months[monthStr];

          const year = parseInt(yearStr, 10);

          const date = new Date(year, month, 1);

          setDate(date);
          setState({ ...state, value: value, title: 'Monthly' })
        } else if (trimmedString.length == 14) {
          const [monthRangeStr, yearStr] = value.split(' - ');

          const quarterStartMonths = {
            'Jan-Mar': 0,
            'Apr-Jun': 3,
            'Jul-Sep': 6,
            'Oct-Dec': 9
          };

          const startMonth = quarterStartMonths[monthRangeStr];

          const year = parseInt(yearStr, 10);

          const date = new Date(year, startMonth, 1);

          setDate(date);
          setState({ ...state, value: value, title: 'Quarterly' })
        } else if (trimmedString.length == 7) {
          const [startYearStr, endYearStr] = value.split('-');

          const startYear = parseInt(startYearStr, 10);

          const date = new Date(startYear, 3, 1);

          setDate(date);
          setState({ ...state, value: value, title: 'Yearly' })
        } else {
          setState({ ...state, value: 'All Time', title: 'All Time' })

        }
      }
    }, [show]);

    useEffect(() => {
      if (value == '' || value == null) {

        onUpdate('All Time')
      } else {
        setState({ ...state, value: value })
      }



    }, [value]);



    const renderYearContent = (year) => {
      return <span >{year}-{(year + 1).toString().slice(-2)}</span>;
    };
    const quarters = { 1: 'Jan-Mar', 2: 'Apr-Jun', 3: 'Jul-Sep', 4: 'Oct-Dec' }
    const renderQuarterContent = (quarter, shortQuarter) => {
      return <span>{quarters[quarter]}</span>;
    };

    return (
      <Dropdown autoClose='outside' show={show} onToggle={(isOpen) => setShow(isOpen)} className={`${parentClass ? parentClass : 'mb-4'}`}>
        <Dropdown.Toggle className={`daterangebtn  w-100 input_i position-relative ${size == 'small' ? 'btn-sm daterangebtn-sm' : 'border bg-white'}`} variant="default" id="dropdown-basic1"  >
          <div className="fieldicon"><MdOutlineCalendarToday /></div> {state.value}
        </Dropdown.Toggle>

        <Dropdown.Menu className="py-0 overflow-hidden shadow" >
          <div className="">
            <div className={`p-2 mb-light`} style={{ width: '256px' }}>
              {options.map((val, i) => (
                <button type="button" key={i} className={`d-inline-block btn btn-sm  ${state.title == val && 'btn-outline-primary'}`} onClick={() => selectOption(val)}>{val}</button>
              ))}



            </div>

            <div className={`col dtpicker_calendar ${state.title == 'All Time' ? 'd-none' : ''}`} >
              <div className="px-3 py-2">


                <div style={{ width: '225px' }}>
                  {state.title == 'Monthly' ?
                    <DatePicker
                      disabledKeyboardNavigation
                      selected={selectedDate}

                      onChange={(e) => onChange(e, 'Monthly')}
                      showMonthYearPicker
                      dateFormat="MM/yyyy"
                      inline
                    />
                    : state.title == 'Quarterly' ?
                      <DatePicker
                        disabledKeyboardNavigation
                        selected={selectedDate}
                        renderQuarterContent={renderQuarterContent}
                        onChange={(e) => onChange(e, 'Quarterly')}
                        showQuarterYearPicker
                        dateFormat="yyyy, QQQ"

                        inline
                      />
                      : state.title == 'Yearly' ?
                        <DatePicker
                          showYearPicker
                          selected={selectedDate}
                          inline
                          renderYearContent={renderYearContent}
                          onChange={(e) => onChange(e, 'Yearly')}
                          dateFormat="yyyy"

                        />
                        : ''
                  }
                </div>
                <div className="text-end">
                  <button onClick={() => applyTime()} className="btn btn-outline-primary btn-sm" disabled={selectedDate ? '' : true}>Apply</button>

                </div>
              </div>

            </div>

          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
);


export function UiTagDisplay(props) {
  const { link, color, title, no_margin, ...other } = props;
  return (
    <div className={`tagbadge ${no_margin == 1 ? '' : 'mb-2'}`}>
      <span className={`text-${color}`}><GoDotFill /></span>
      {title}
    </div>
  );
}

export function UiActioButton(props) {
  const { action, className, tooltip, type, title, ...other } = props;
  return (
    <OverlayTrigger overlay={<Tooltip >{tooltip ? tooltip : title}</Tooltip>}>

      <button type={type ? type : 'button'} className={`btn bg-white me-1 ${title == 'Delete' ? 'text-danger' : title == 'Close' ? 'text-secondary' : 'text-primary'} ${className}`} onClick={action}>
        {title == 'Edit' && <span className="sicon"><MdEdit /></span>}
        {title == 'Delete' && <span className="sicon"><MdOutlineDelete /></span>}
        {title == 'Go' && <span className="sicon"><MdArrowForward /></span>}
        {title == 'Clear' && <span className="sicon"><MdOutlineRefresh /></span>}
        {title == 'More' && <span className="sicon"><AiOutlinePlus /></span>}
        {title == 'Add' && <span className="sicon"><MdAdd /></span>}
        {title == 'Less' && <span className="sicon"><AiOutlineMinus /></span>}
        {title == 'Refresh' && <span className="sicon"><HiOutlineRefresh /></span>}
        {title == 'UpdateDate' && <span className="sicon"><MdUpdate /></span>}
        {title == 'User' && <span className="sicon"><AiOutlineUserAdd /></span>}
        {title == 'Verify' && <span className="sicon"><MdCheck /></span>}
        {title == 'Copy' && <span className="sicon"><MdContentCopy /></span>}
        {title == 'Settings' && <span className="sicon"><BsFillGearFill /></span>}
        {title == 'Show' && <span className="sicon"><BiShow /></span>}
        {title == 'Hide' && <span className="sicon"><BiHide /></span>}
        {title == 'Close' && <span className="sicon"><MdClose /></span>}
      </button>
    </OverlayTrigger>
  );
}


export function UiAvatarGroup(props) {
  const { items, className, tooltip, title, ...other } = props;
  const [show, setShow] = useState(false);

  return (
    <div className="d-flex">
      <ConfigProvider
        theme={{
          components: {
            Popover: {
              zIndexPopup: 9991
            },
          },
        }}
      >
        <Avatar1.Group maxCount={3} maxPopoverTrigger="click" maxStyle={{ color: '#ffffff', backgroundColor: 'var(--bs-dark)', cursor: 'pointer' }}>

          {(items && items.length > 0) && items.map((val, i) => (
            <>
              {val.name &&
                <Tooltip1 key={i} title={val.name} placement="top">
                  <Avatar1 className="bg-secondary" src={val.photo ? val.photo : null} >{val.name.charAt(0)}</Avatar1>
                </Tooltip1>
              }
            </>
          ))}
        </Avatar1.Group>
      </ConfigProvider>

    </div>
  );
}


export function UiActionDropDown(props) {
  const { items, className, newclassName, tooltip, title, ...other } = props;
  const [show, setShow] = useState(false);

  return (
    <DropdownMenu
      trigger={({ triggerRef, isSelected, ...props }) => (
        <button className={`${newclassName ? newclassName : 'btn btn-light'} ${className}`}
          {...props}

          ref={triggerRef}
        ><MdOutlineMoreVert /></button>

      )}
    >
      <DropdownItemGroup {...other}>

      </DropdownItemGroup>
    </DropdownMenu>


  )
}




export const UiDateRangePicker = forwardRef(({ icol, className, dateFormat, message, lcol, label, required, default_val, name, onChange, title, ...props }, ref) => {

  const ExampleCustomInput = forwardRef(({ value, onClick, onChange, name, className }, ref) => (
    <>
      <div className="fieldicon">
        <MdOutlineCalendarToday />
      </div>
      <input onClick={onClick} ref={ref} value={value} onChange={onChange} name={name} className={className} />
    </>


  ));
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    if (startDate && endDate) {
      onChange(format(startDate, 'dd-MM-yyyy') + ' - ' + format(endDate, 'dd-MM-yyyy'))
    }
  }, [startDate, endDate]);


  return (
    <div className={`mb-4 ${lcol ? "row" : ""}`}>
      <label
        className={`form-label  ${lcol ? "pt-sm-2 col-sm-" + lcol : ""
          }`}
      >
        {label}{" "}
        <span className="text-red font-bold text-lg">
          {required && "*"}
        </span>
      </label>
      <div className={`${icol ? "col-sm-" + icol : ""}`}>

        <DatePicker
          className={`form-control ${message ? "is-invalid" : ""
            } ${className} input_i`}

          popperPlacement="bottom-start"
          showPopperArrow={false}

          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={(update) => { setDateRange(update); }}
          //onChange={(e) => onChange(setDateRange(e))} 

          dateFormat="dd-MM-yyyy"

          name={name}

          ref={ref}
          {...props}
          customInput={<ExampleCustomInput />}
        />
        {message && <div className="invalid-feedback d-block">{message}</div>}

      </div>
    </div>
  );
});




export const UiTimePicker = forwardRef(({ icol, className, dateFormat, message, lcol, label, required, default_val, name, onChange, title, ...props }, ref) => {

  const onChange1 = (time, timeString) => {
    onChange(timeString);
  };
  return (
    <div className={`mb-4 ${lcol ? "row" : ""}`}>
      <label
        className={`form-label  ${lcol ? "pt-sm-2 col-sm-" + lcol : ""
          }`}
      >
        {label}{" "}
        <span className="text-red font-bold text-lg">
          {required && "*"}
        </span>
      </label>
      <div className={`${icol ? "col-sm-" + icol : ""}`}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#1A73E8',
            },
          }}
        >
          <TimePicker
            className={`form-control ${message ? "is-invalid" : ""} ${className} `}
            ref={ref}
            name={name}
            format="HH:mm"
            onChange={onChange1}
            defaultValue={default_val && dayjs(default_val, 'HH:mm')}

            {...props}


          />
        </ConfigProvider>

        {message && <div className="invalid-feedback d-block">{message}</div>}

      </div>
    </div>
  );
});




export function UiAvatar(props) {
  const { name, photo, className, tooltip, title } = props;

  return (
    <div className="d-flex">



      {name && (
        <OverlayTrigger overlay={<Tooltip >{name}</Tooltip>} >
          <>
            <Avatar name={name} src={photo ? photo : null} className="bg-secondary" size="30" />
          </>
        </OverlayTrigger>
      )}



    </div>
  );
}

export function UiAvatarName(props) {
  const { name, photo, className, tooltip, title, ...other } = props;

  return (
    <div className="d-flex align-items-center">



      {name && (
        <>
          <Avatar name={name} src={photo ? photo : null} className="bg-secondary me-2" size="25" />
          <span className="text-secondary">{name}</span>
        </>
      )}



    </div>
  );
}

export function UiTimeDisplay(props) {
  const { time } = props;

  return (
    <div className="d-flex align-items-center">

      <span><MdAccessTime className="fs-3 me-2" /><span className="text-secondary">{formatRelative(parse(time, "yyyy-MM-dd HH:mm:ss", new Date()), new Date(), { locale })}</span></span>

    </div>
  );
}


export function Avatar(props) {
  let { name, src, className, size, title, round, ...other } = props;

  return (
    <div className="d-flex">



      {(name || src) && (
        <div className={`u_avatar ${className}`} style={{ width: size + 'px', height: size + 'px' }} {...other}>
          {src ? <img src={src} /> : <div style={{ width: size + 'px', height: size + 'px' }}><span>{name.charAt(0)}</span></div>}

        </div>

      )}



    </div>
  );
}


export const PageError = forwardRef(
  (
    {
      msg,
      code,
      ...props
    },
    ref
  ) => {
    return (
      <div className="text-center mt-5">
        <span className="text-warning" style={{ fontSize: '60px' }}><BsFillExclamationTriangleFill /></span>
        <h2>{code == 4 ? 'Not Found!' : msg}</h2>

      </div>

    );
  }
);

export const Toastcontent = (props) => {
  const userModal = useModal('bulkaction-errors');

  const showAddModal = (row, type) => {
    userModal.show({ data: row, type: type }).then((res) => {

    });
  }
  const { msg, errors, type } = props;
  return (<>
    <span>{msg}</span>
    {errors &&
      <button className="btn btn-secondary btn-sm ms-2" onClick={() => showAddModal(errors, type)}>View errors</button>
    }
  </>
  )
}


export const UiPopover = (props) => {

  const { msg, errors, type } = props;
  return (<div className="ms-1" style={{ marginTop: '2px' }}>
    <OverlayTrigger overlay={<Tooltip >{msg}</Tooltip>}>
      <div className="d-flex"><IoInformationCircleOutline className="fs-5 text-secondary" /></div>
    </OverlayTrigger>
  </div>
  )
}



export const UiClientSelect = forwardRef(
  (
    {

      label,
      required,
      placeholder,
      loadOptions,
      active,
      className,
      value,
      name,
      lcol,
      isDisabled,
      parentClass,
      icol,
      message,
      fetch_onload,
      ...props
    },
    ref
  ) => {

    const client_state = useStore((state) => state.load_clients);
    async function fetchData(active) {

      const { data } = await instance.get(
        "/fetch_clients?s=all_clients&active=" + active
      );
      var dataa = data;
      return dataa;
    }

    const { data, error, isError, isLoading, isFetching, refetch } = useQuery(["all_clients", active], () => fetchData(active), {
      keepPreviousData: true,
      staleTime: 600000,
      enabled: false
    });

    useEffect(() => {
      if (fetch_onload) {
        if (!data || client_state == false) {
          refetch()
          useStore.setState({ load_clients: true });
        }
      }

    }, []);

    const fetchOptions1 = () => {
      if (!data || client_state == false) {
        refetch()
        useStore.setState({ load_clients: true });
      }
    }


    return (
      <div className={`${parentClass ? parentClass : "mb-4"} ${lcol ? "row" : ""}`}>
        <label
          className={`form-label ${lcol ? "pt-sm-2 col-sm-" + lcol : ""
            }`}
        >
          {label}{" "}
          <span className="text-red font-bold text-lg">
            {required && "*"}
          </span>
        </label>
        <div className={icol ? "col-sm-" + icol : ""}>

          <Select1
            name={name}
            disabled={isDisabled}
            status={message && "error"}
            // value={value?.value ? { 'label': value.label, 'value': parseInt(value.value) } : null}
            value={value}
            suffixIcon={<MdOutlineKeyboardArrowDown />}
            clearIcon={<MdOutlineClose className="text-secondary" />}
            size='large'
            labelInValue={true}
            style={{ width: '100%' }}
            loading={isFetching ? true : false}
            onFocus={() => fetchOptions1()}
            showSearch="multiple"
            notFoundContent={<div className="text-center">{isFetching ? 'Loading...' : 'No options'}</div>}
            //options={data ? data.items : []}
            optionLabelProp="label"
            allowClear={true}
            filterOption={(input, option) => (option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.secondary.toLowerCase().indexOf(input.toLowerCase()) >= 0)
            }
            placeholder='Select...'

            ref={ref}


            className={`rselect ${message ? "is-invalid" : ""
              } ${className}`}

            {...props}


          >
            {data?.items?.map((val, i) => (
              <Option key={i} value={val.value} label={val.label} secondary={val.secondary_text}>{val.label}{val.secondary_text &&
                <small className="d-block">{val.secondary_text}</small>
              }</Option>
            ))}
          </Select1>
          {message && <div className="invalid-feedback">{message}</div>}
          <input type="hidden" name={name} value={value?.value ? value.value : ''}></input>
        </div>
      </div>
    );
  }
);



export const UiTagsList = (props) => {

  const { items } = props;
  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  function renderRest(items) {
    return (
      <Popover
      content={<div>
        {items.map((val, i) => (
          <UiTagDisplay title={val.tag} color={val.color} key={i} />
        ))}
      </div>}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
            <div className="tagbadge c-pointer">+{items.length}</div>

    </Popover>
      
    );
  }


  return (
    <Overflow
      prefixCls="custom-overflow"
      data={items}
      renderRest={renderRest}

      renderItem={(val, index) => <UiTagDisplay title={val.tag} color={val.color} key={index} no_margin={1}/>}
      maxCount="responsive" 
    />
  )
}