import "bootstrap/dist/js/bootstrap.bundle.min.js";
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { useStore } from "./state.js";
import { getStorage } from "./helper";
import './styles.scss';
import {
  Routes,
  Route,
  BrowserRouter,
  Link,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import ReactGA from "react-ga4";


import reportWebVitals from './reportWebVitals';
import Profile from "./routes/profile";
import Login from "./routes/login";
import TwoFA from "./routes/2fa";
import ForgotPassword from "./routes/reset_password";
import ChangePassword from "./routes/change_password";
import Layout from "./layout";
import Settings from "./routes/settings";
import Tags from "./routes/tags";
import ClientSettings, { CustomFields, ClientGenSettings } from "./routes/client_settings";
import Home from "./routes/home";
import Modal from "./modals/tag";
import Group from "./modals/group";
import Crop from "./modals/crop";
import CustomField from "./modals/custom_field";
import ServiceCustomField from "./modals/service_custom_field";
import Passwords from "./modals/password";
import RecurringServices from "./modals/recurring_service";
import Portals from "./routes/portals";
import Services from "./routes/services";
import Users from "./routes/users";
import User from "./routes/user";
import UserRoles from "./routes/user_roles";
import UserRole from "./routes/user_role";
import GeneralNotifications from "./routes/general_notifications";
import TaskNotifications from "./routes/task_notifications";
import GeneralNotification from "./routes/general_notification";
import TaskNotification from "./routes/task_notification";
import Clients from "./routes/clients";
import Client from "./routes/client";
import Package, { PackageSettings } from "./routes/package";
import PackageClients from "./routes/package/clients.js";
import ViewClient, { Details } from "./routes/view_client";
import ClientPasswords from "./routes/view_client/passwords";
import ClientServices from "./routes/view_client/services";
import ClientDocRegister from "./routes/view_client/doc_register";
import ClientTasks from "./routes/view_client/tasks";
import ClientExpenses from "./routes/view_client/expenses";
import ClientDocuments from "./routes/view_client/documents";
import ClientLedger from "./routes/view_client/ledger";
import ViewTask, { TaskDetails } from "./routes/view_task";
import TaskDocuments from "./routes/view_task/documents";
import TimeLog from "./routes/view_task/timelog";
import TaskTodo from "./routes/view_task/todo";
import TaskHistory from "./routes/view_task/task_history";
import ReferFriends from "./routes/refer";
import BillingSettings from "./routes/billing_settings";
import BillingEntity from "./routes/billing_entity";
import WebsiteSettings from "./routes/website_settings";
import NotificationSettings from "./routes/notification_settings";
import Expenses from "./routes/expenses";
import Quotations from "./routes/quotations";
import Expense from "./routes/expense";
import ViewExpense from "./routes/view_expense";
import ExpCategories from "./routes/expense_categories";
import DocumentsRegister from "./routes/documents_in_out_register";
import DocCollectionReq from "./routes/doc_collection";
import DscManagement from "./routes/dsc";
import DscSettings from "./routes/dsc_settings";
import ExpCategory from "./modals/expense_category";
import Tasks from "./routes/tasks";
import ClientPackages from "./routes/client_packages";
import Invoices from "./routes/invoices";
import Receipts from "./routes/receipts";
import Receipt from "./routes/receipt";
import Invoice from "./routes/invoice";
import Quotation from "./routes/quotation";
import ToDos from "./routes/todos";
import UserNotifications from "./routes/user_notifications";
import Holidays from "./routes/holidays";
import ViewPackage from "./routes/view_package";
import ViewInvoice from "./routes/view_invoice";
import ViewQuote from "./routes/view_quotation";
import ViewReceipt from "./routes/view_receipt";
import AttendanceSettings from "./routes/attendance_settings";
import DocTypes from "./routes/document_types";
import PaymentModes from "./routes/payment_modes";
import Task from "./routes/task";
import Pricing from "./routes/pricing";
import AccountSettings, { AccountDetails } from "./routes/account_settings";
import BillingHistory from "./routes/account_settings/billing";
import NoMatch from "./routes/404";
import Groups from "./routes/groups";
import Packages from "./routes/packages";
import Subscribe from "./routes/subscribe";
import Onboarding from "./routes/onboarding";
import SendNotifications from "./routes/send_notifications";
import AttendanceDashboard, { AttendanceList, AttendanceDaily } from "./routes/attendance";
import Service, { ServiceCF, ServiceCheckList, ServiceSettings } from "./routes/service/index.js";
import ServiceClients from "./routes/service/clients.js";
import ServiceSubtask from "./routes/service/subtask.js";


import Portal from "./modals/portal";
import AddService from "./modals/add_service";
import SelectTemplate from "./modals/template";
import AutoBilling from "./modals/auto_billing";
import LogTime from "./modals/logtime";
import AddTodo from "./modals/todo";
import TaskTags from "./modals/task_tags";
import TaskUsers from "./modals/task_user";
import TaskDueDate from "./modals/task_due_date";
import TaskStatus from "./modals/task_status";
import TaskVerify from "./modals/task_verify";
import PdfPrint from "./modals/print";
import AddAttendance from "./modals/add_attendance";
import DocType from "./modals/document_type";
import DocEntry from "./modals/document_register";
import DscEntry from "./modals/dsc";
import DocReturn from "./modals/document_returned";
import SmsBuy from "./modals/sms_buy";
import PG from "./modals/pg";
import ChangePasswordProfile from "./modals/change_password";
import Enable2fa from "./modals/enable2fa";
import Disable2fa from "./modals/disable2fa";
import Holiday from "./modals/holiday";
import ViewToDo from "./modals/view_todo";
import ImportEntries from "./modals/import.js";
import BulkActionErrors from "./modals/bulk_error";
import PGOnly from "./modals/pg1";
import ExportAll from "./modals/export";
import DocCollection from "./modals/doc_collection";
import DocReqList from "./modals/doc_request";
import SendEmail from "./modals/send_email.js";
import SendWhatsapp from "./modals/send_whatsapp.js";
import ExportTally from "./modals/tally.js";
import StopWatch from "./modals/timer.js";
import StopTimer from "./modals/stop_timer.js";
import TaskDescription from "./modals/task_description.js";
import GSTclient from "./modals/gst_cllient.js";
import AppUpdates from "./modals/updates.js";
import InvoiceExpenses from "./modals/invoice_expenses.js";
import AppNotifications from "./modals/notifications.js";
import QuoteStatus from "./modals/quote_status.js";
import ClientPackage from "./modals/client_package.js";
import ClientRecurringPackage from "./modals/recurring_package.js";
import ClientServiceUsers from "./modals/client_service_user.js";
import AssignClients from "./modals/assign_clients.js";
import ClientServicePrice from "./modals/client_service_price.js";
import BulkServiceAssign from "./modals/bulk_service_assign.js";
import CustUsers from "./modals/cust_user.js";
import PaymentMode from "./modals/payment_mode.js";
import SubtaskModal from "./modals/add_subtask.js";
import CustomColumn from "./modals/custom_column.js";


import ClientsReport from "./routes/clients_report";
import ServicesReport from "./routes/services_report";
import TasksReport from "./routes/tasks_report";
import EmployeesReport from "./routes/employees_report";
import EmployeesTimeReport from "./routes/employees_wise_time_report";
import ClientsTimeReport from "./routes/client_wise_time_report";
import InvoicesReport from "./routes/invoices_report";
import ReceiptsReport from "./routes/receipts_report";
import ExpensesReport from "./routes/expenses_report";
import AttendanceReport from "./routes/attendance_report";
import UnbilledTasksReport from "./routes/unbilled_tasks_report";
import ClientOutstandingReport from "./routes/client_outstanding_report";
import PasswordsReport from "./routes/password_report";
import TimelogReport from "./routes/timelog_report";
import DSCReport from "./routes/dsc_report";
import UnbilledPackagesReport from "./routes/unbilled_packages_report";


import BrandKit from "./routes/brand_kit";
import PayProcessing from "./routes/processing";


import './styles.css';
import NiceModal, { ModalDef, useModal, reducer } from '@ebay/nice-modal-react';

if (getStorage('token')) {
  useStore.setState({ auth: 1 });
  //setAuth('1');

}
function AuthRoute({ children }) {
  const auth = useStore((state) => state.auth)
  let location = useLocation();

  if (!auth) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function ReverseAuthRoute({ children }) {
  const auth = useStore((state) => state.auth)

  if (auth) {
    return <Navigate to="/" replace />;
  }

  return children;
}







export default function App() {
  const ctu = useStore((state) => state.ctu)
  let navigate = useNavigate();
  useEffect(() => {
    window.addEventListener('hashchange', (event) => {
      //console.log(event.oldURL.split("#")[1])
      var mid = event.oldURL.split("#")[1];
      if (mid) {
        //NiceModal.hide(event.oldURL.split("#")[1])
      }
      if (window.location.hash) {
        //NiceModal.show(window.location.hash.split("#")[1])
      }
    });


  }, []);

  const location = useLocation();



  useEffect(() => {
    if (ctu) {
      ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, customParameters: { client_id: ctu } });
    } else {
      ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    }
  }, [location]);




  return (
    <>
      <Routes>

        <Route path="/" element={<AuthRoute><Layout /></AuthRoute>}  >
          <Route path="/" element={<AuthRoute><Home /></AuthRoute>} />
          <Route path="profile" element={<AuthRoute><Profile /></AuthRoute>} />
          <Route path="settings" element={<AuthRoute><Settings /></AuthRoute>} />
          <Route path="tags" element={<AuthRoute><Tags /></AuthRoute>} />
          <Route path="portals" element={<AuthRoute><Portals /></AuthRoute>} />
          <Route path="services" element={<AuthRoute><Services /></AuthRoute>} />
          <Route path="packages" element={<AuthRoute><Packages /></AuthRoute>} />
          <Route path="user-roles" element={<AuthRoute><UserRoles /></AuthRoute>} />
          <Route path="users" element={<AuthRoute><Users /></AuthRoute>} />
          <Route path="user/:id" element={<AuthRoute><User /></AuthRoute>} />
          <Route path="user-role/:id" element={<AuthRoute><UserRole /></AuthRoute>} />
          <Route path="billing-settings" element={<AuthRoute><BillingSettings /></AuthRoute>} />
          <Route path="billing-entity/:id" element={<AuthRoute><BillingEntity /></AuthRoute>} />
          <Route path="website-settings" element={<AuthRoute><WebsiteSettings /></AuthRoute>} />
          <Route path="attendance-settings" element={<AuthRoute><AttendanceSettings /></AuthRoute>} />
          <Route path="general-notifications" element={<AuthRoute><GeneralNotifications /></AuthRoute>} />
          <Route path="task-notifications" element={<AuthRoute><TaskNotifications /></AuthRoute>} />
          <Route path="general-notification/:id" element={<AuthRoute><GeneralNotification /></AuthRoute>} />
          <Route path="task-notification/:id" element={<AuthRoute><TaskNotification /></AuthRoute>} />
          <Route path="clients" element={<AuthRoute><Clients /></AuthRoute>} />
          <Route path="quotations" element={<AuthRoute><Quotations /></AuthRoute>} />
          <Route path="holidays" element={<AuthRoute><Holidays /></AuthRoute>} />
          <Route path="groups" element={<AuthRoute><Groups /></AuthRoute>} />
          <Route path="payment-modes" element={<AuthRoute><PaymentModes /></AuthRoute>} />
          <Route path="clients-report" element={<AuthRoute><ClientsReport /></AuthRoute>} />
          <Route path="services-report" element={<AuthRoute><ServicesReport /></AuthRoute>} />
          <Route path="tasks-report" element={<AuthRoute><TasksReport /></AuthRoute>} />
          <Route path="employees-report" element={<AuthRoute><EmployeesReport /></AuthRoute>} />
          <Route path="employee-wise-time-report" element={<AuthRoute><EmployeesTimeReport /></AuthRoute>} />
          <Route path="client-wise-time-report" element={<AuthRoute><ClientsTimeReport /></AuthRoute>} />
          <Route path="dsc-report" element={<AuthRoute><DSCReport /></AuthRoute>} />
          <Route path="invoices-report" element={<AuthRoute><InvoicesReport /></AuthRoute>} />
          <Route path="receipts-report" element={<AuthRoute><ReceiptsReport /></AuthRoute>} />
          <Route path="expenses-report" element={<AuthRoute><ExpensesReport /></AuthRoute>} />
          <Route path="passwords-report" element={<AuthRoute><PasswordsReport /></AuthRoute>} />
          <Route path="attendance-report" element={<AuthRoute><AttendanceReport /></AuthRoute>} />
          <Route path="timelog-report" element={<AuthRoute><TimelogReport /></AuthRoute>} />
          <Route path="unbilled-tasks-report" element={<AuthRoute><UnbilledTasksReport /></AuthRoute>} />
          <Route path="unbilled-packages-report" element={<AuthRoute><UnbilledPackagesReport /></AuthRoute>} />
          <Route path="client-outstanding-report" element={<AuthRoute><ClientOutstandingReport /></AuthRoute>} />
          <Route path="website-promo" element={<AuthRoute><BrandKit /></AuthRoute>} />
          <Route path="dsc-settings" element={<AuthRoute><DscSettings /></AuthRoute>} />
          <Route path="doc-requests" element={<AuthRoute><DocCollectionReq /></AuthRoute>} />
          <Route path="user-notifications" element={<AuthRoute><UserNotifications /></AuthRoute>} />
          <Route path="refer" element={<AuthRoute><ReferFriends /></AuthRoute>} />
          <Route path="send-notifications" element={<AuthRoute><SendNotifications /></AuthRoute>} />



          <Route path="tasks" element={<AuthRoute><Tasks /></AuthRoute>} />
          <Route path="client-packages" element={<AuthRoute><ClientPackages /></AuthRoute>} />
          <Route path="client/:id" element={<AuthRoute><Client /></AuthRoute>} />
          <Route path="task/:id" element={<AuthRoute><Task /></AuthRoute>} />
          <Route path="invoices" element={<AuthRoute><Invoices /></AuthRoute>} />
          <Route path="todos" element={<AuthRoute><ToDos /></AuthRoute>} />
          <Route path="receipts" element={<AuthRoute><Receipts /></AuthRoute>} />
          <Route path="expenses" element={<AuthRoute><Expenses /></AuthRoute>} />
          <Route path="pricing/:action" element={<AuthRoute><Pricing /></AuthRoute>} />
          <Route path="notification-settings" element={<AuthRoute><NotificationSettings /></AuthRoute>} />
          <Route path="expense-categories" element={<AuthRoute><ExpCategories /></AuthRoute>} />
          <Route path="document-types" element={<AuthRoute><DocTypes /></AuthRoute>} />
          <Route path="documents-register" element={<AuthRoute><DocumentsRegister /></AuthRoute>} />
          <Route path="dsc-management" element={<AuthRoute><DscManagement /></AuthRoute>} />
          <Route path="invoice/:id" element={<AuthRoute><Invoice /></AuthRoute>} />
          <Route path="quotation/:id" element={<AuthRoute><Quotation /></AuthRoute>} />
          <Route path="receipt/:id" element={<AuthRoute><Receipt /></AuthRoute>} />
          <Route path="expense/:id" element={<AuthRoute><Expense /></AuthRoute>} />
          <Route path="view-package/:id" element={<AuthRoute><ViewPackage /></AuthRoute>} />
          <Route path="view-expense/:id" element={<AuthRoute><ViewExpense /></AuthRoute>} />
          <Route path="view-invoice/:id" element={<AuthRoute><ViewInvoice /></AuthRoute>} />
          <Route path="view-quotation/:id" element={<AuthRoute><ViewQuote /></AuthRoute>} />
          <Route path="view-receipt/:id" element={<AuthRoute><ViewReceipt /></AuthRoute>} />
          <Route path="view-client/:id" element={<AuthRoute><ViewClient /></AuthRoute>} >
            <Route index element={<AuthRoute><Details /></AuthRoute>} />
            <Route path="passwords" element={<AuthRoute><ClientPasswords /></AuthRoute>} />
            <Route path="services" element={<AuthRoute><ClientServices /></AuthRoute>} />
            <Route path="tasks" element={<AuthRoute><ClientTasks /></AuthRoute>} />
            <Route path="tasks/:id1" element={<AuthRoute><ClientTasks /></AuthRoute>} />
            <Route path="documents" element={<AuthRoute><ClientDocuments /></AuthRoute>} />
            <Route path="ledger" element={<AuthRoute><ClientLedger /></AuthRoute>} />
            <Route path="doc-register" element={<AuthRoute><ClientDocRegister /></AuthRoute>} />
            <Route path="expenses" element={<AuthRoute><ClientExpenses /></AuthRoute>} />
            <Route path="expenses/:id1" element={<AuthRoute><ClientExpenses /></AuthRoute>} />

          </Route>


          <Route path="account" element={<AuthRoute><AccountSettings /></AuthRoute>} >
            <Route index element={<AuthRoute><AccountDetails /></AuthRoute>} />
            <Route path="billing-history" element={<AuthRoute><BillingHistory /></AuthRoute>} />


          </Route>

          <Route path="attendance" element={<AuthRoute><AttendanceDashboard /></AuthRoute>} >
            <Route index element={<AuthRoute><AttendanceDaily /></AuthRoute>} />
            <Route path="monthly" element={<AuthRoute><AttendanceList /></AuthRoute>} />


          </Route>




          <Route path="view-task/:id" element={<AuthRoute><ViewTask /></AuthRoute>} >
            <Route index element={<AuthRoute><TaskDetails /></AuthRoute>} />
            <Route path="documents" element={<AuthRoute><TaskDocuments /></AuthRoute>} />
            <Route path="timelog" element={<AuthRoute><TimeLog /></AuthRoute>} />
            <Route path="todo" element={<AuthRoute><TaskTodo /></AuthRoute>} />
            <Route path="history" element={<AuthRoute><TaskHistory /></AuthRoute>} />


          </Route>


          <Route path="service/:id" element={<AuthRoute><Service /></AuthRoute>} >
            <Route index element={<AuthRoute><ServiceSettings /></AuthRoute>} />
            <Route path="checklist" element={<AuthRoute><ServiceCheckList /></AuthRoute>} />
            <Route path="custom-fields" element={<AuthRoute><ServiceCF /></AuthRoute>} />
            <Route path="clients" element={<AuthRoute><ServiceClients /></AuthRoute>} />
            <Route path="subtask" element={<AuthRoute><ServiceSubtask /></AuthRoute>} />
          </Route>

          <Route path="package/:id" element={<AuthRoute><Package /></AuthRoute>} >
            <Route index element={<AuthRoute><PackageSettings /></AuthRoute>} />
            <Route path="clients" element={<AuthRoute><PackageClients /></AuthRoute>} />

          </Route>

          <Route path="client-settings" element={<AuthRoute><ClientSettings /></AuthRoute>} >
            <Route index element={<AuthRoute><ClientGenSettings /></AuthRoute>} />
            <Route path="custom-fields" element={<AuthRoute><CustomFields /></AuthRoute>} />
          </Route>

          <Route path="*" element={<AuthRoute><NoMatch /></AuthRoute>} />
        </Route>
        <Route path="/payment-process/:id" element={<PayProcessing />} />
        <Route path="/login" element={<ReverseAuthRoute><Login /></ReverseAuthRoute>} />
        <Route path="/signup" element={<ReverseAuthRoute><Subscribe /></ReverseAuthRoute>} />
        <Route path="/signup/:id" element={<ReverseAuthRoute><Subscribe /></ReverseAuthRoute>} />
        <Route path="/onboarding/:id" element={<ReverseAuthRoute><Onboarding /></ReverseAuthRoute>} />
        <Route path="forgot-password" element={<ReverseAuthRoute><ForgotPassword /></ReverseAuthRoute>} />
        <Route path="change-password/:id" element={<ReverseAuthRoute><ChangePassword /></ReverseAuthRoute>} />
        <Route path="2fa/:id" element={<ReverseAuthRoute><TwoFA /></ReverseAuthRoute>} />


      </Routes>
      <ModalDef id="user" component={Modal} />
      <ModalDef id="customfield" component={CustomField} />
      <ModalDef id="servicecustomfield" component={ServiceCustomField} />
      <ModalDef id="portal" component={Portal} />
      <ModalDef id="add-service" component={AddService} />
      <ModalDef id="crop" component={Crop} />
      <ModalDef id="select-template" component={SelectTemplate} />
      <ModalDef id="group" component={Group} />
      <ModalDef id="password" component={Passwords} />
      <ModalDef id="recurring-services" component={RecurringServices} />
      <ModalDef id="auto-billing" component={AutoBilling} />
      <ModalDef id="log-time" component={LogTime} />
      <ModalDef id="todo" component={AddTodo} />
      <ModalDef id="tasktags" component={TaskTags} />
      <ModalDef id="taskusers" component={TaskUsers} />
      <ModalDef id="task-duedate" component={TaskDueDate} />
      <ModalDef id="task-status" component={TaskStatus} />
      <ModalDef id="task-verify" component={TaskVerify} />
      <ModalDef id="print" component={PdfPrint} />
      <ModalDef id="expense-category" component={ExpCategory} />
      <ModalDef id="add-attendance" component={AddAttendance} />
      <ModalDef id="document-type" component={DocType} />
      <ModalDef id="document-entry" component={DocEntry} />
      <ModalDef id="document-return" component={DocReturn} />
      <ModalDef id="buy-sms" component={SmsBuy} />
      <ModalDef id="payment" component={PG} />
      <ModalDef id="change-profile-password" component={ChangePasswordProfile} />
      <ModalDef id="enable-two-step-auth" component={Enable2fa} />
      <ModalDef id="disable-two-step-auth" component={Disable2fa} />
      <ModalDef id="holiday" component={Holiday} />
      <ModalDef id="view-todo" component={ViewToDo} />
      <ModalDef id="import-data" component={ImportEntries} />
      <ModalDef id="bulkaction-errors" component={BulkActionErrors} />
      <ModalDef id="payment-gateway" component={PGOnly} />
      <ModalDef id="export-all" component={ExportAll} />
      <ModalDef id="dsc-entry" component={DscEntry} />
      <ModalDef id="doc-collection" component={DocCollection} />
      <ModalDef id="doc-request" component={DocReqList} />
      <ModalDef id="send-email" component={SendEmail} />
      <ModalDef id="send-whatsapp" component={SendWhatsapp} />
      <ModalDef id="export-tally" component={ExportTally} />
      <ModalDef id="timesheet" component={StopWatch} />
      <ModalDef id="stop-timer" component={StopTimer} />
      <ModalDef id="task-description" component={TaskDescription} />
      <ModalDef id="gst-client" component={GSTclient} />
      <ModalDef id="whatsnew" component={AppUpdates} />
      <ModalDef id="invoice-expenses" component={InvoiceExpenses} />
      <ModalDef id="app-notifications" component={AppNotifications} />
      <ModalDef id="quote-status" component={QuoteStatus} />
      <ModalDef id="client-package" component={ClientPackage} />
      <ModalDef id="recurring-package" component={ClientRecurringPackage} />
      <ModalDef id="client-service-users" component={ClientServiceUsers} />
      <ModalDef id="client-service-price" component={ClientServicePrice} />
      <ModalDef id="assign-clients" component={AssignClients} />
      <ModalDef id="assign-services" component={BulkServiceAssign} />
      <ModalDef id="cust-users" component={CustUsers} />
      <ModalDef id="payment-mode" component={PaymentMode} />
      <ModalDef id="add-subtask" component={SubtaskModal} />
      <ModalDef id="customize-column" component={CustomColumn} />

    </>
  );
}





// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
