import React, { useState, useEffect, useMemo } from "react";
import { instance } from "../../axios";
import { TableZero, UiDateRange, UiActioButton, UiSelect, PageError, UiButton, UiActionDropDown, UiRSelect, BgLoader, UiAvatarGroup, InLoader } from "../../ui";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { useQuery, useQueryClient, select } from "react-query";
import { MdVerified } from 'react-icons/md'
import { CSVLink, CSVDownload } from "react-csv";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react'
import queryString from 'query-string';
import { useStore } from "../../state.js";
import { FiUsers } from 'react-icons/fi'
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { DropdownItem } from '@atlaskit/dropdown-menu';



export default function ClientTasks() {
  const [pstate, setPSate] = useOutletContext();
  let { id1 } = useParams();
  let navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient()

  const parsed = queryString.parse(location.search);

  const [state, setState] = useState({ page: 1 });
  const [pagenum, setPage] = useState(1);

  const [state1, setState1] = useState({});
  const [filter, setFilter] = useState({});
  const [toggleCleared, setToggleCleared] = useState(false);
  const [tableDisable, setTableDisable] = useState(false);


  const schema = yup.object().shape(
    {


    }
  );
  const statusModal = useModal('task-status');
  const duedateModal = useModal('task-duedate');
  const userModal = useModal('taskusers');
  const verifyModal = useModal('task-verify');
  useEffect(() => {


    const parsed = queryString.parse(location.search);

    if (parsed.page) {
      setPage(parseInt(parsed.page))
    } else {
      // setPage(1)
    }

    setFilter(parsed)


  }, []);


  const showstatusModal = (id) => {
    if (id.length == 1) {
      var tid = id[0].id;
      var val = id[0].status;
    } else if (id.length > 1) {
      var col0 = id.map(d => d['id']);
      var tid = col0.toString();
      var val = null;
    }
    statusModal.show({ task_id: tid, tval: val }).then((res) => {
      queryClient.invalidateQueries('client_tasks')

    });

  }

  const showduedateModal = (id, type) => {
    if (id.length == 1) {
      var tid = id[0].id;
      var val = type == 'due_date' ? id[0].due_date : id[0].target_date;
    } else if (id.length > 1) {
      var col0 = id.map(d => d['id']);
      var tid = col0.toString();
      var val = null;
    }
    duedateModal.show({ task_id: tid, date: val, datetype: type }).then((res) => {
      queryClient.invalidateQueries('client_tasks')


    });

  }


  const showuserModal = (id) => {
    if (id.length == 1) {
      var tid = id[0].id.toString();

    } else if (id.length > 1) {
      var col0 = id.map(d => d['id']);
      var tid = col0.toString();

    }
    userModal.show({ task_id: tid }).then((res) => {
      queryClient.invalidateQueries('client_tasks')


    });

  }


  const showverifyModal = (id) => {
    if (id.length == 1) {
      var tid = id[0].id.toString();

    } else if (id.length > 1) {
      var col0 = id.map(d => d['id']);
      var tid = col0.toString();

    }
    setTableDisable(true)

    instance({
      method: "GET",
      url: "/verify_task?id=" + tid + '&action=1',
    })
      .then(function (response) {
        setTableDisable(false);
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          toast(response.data.msg, { type: "success" });
          queryClient.invalidateQueries('client_tasks')


        }
      })






  }


  const handleDelete = (id) => {
    if (id.length == 1) {
      var tid = id[0].id.toString();

    } else if (id.length > 1) {
      var col0 = id.map(d => d['id']);
      var tid = col0.toString();

    }

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setTableDisable(true)
        instance
          .get("/delete_task?delete=" + tid)
          .then(function (response) {
            setTableDisable(false)
            if (response.data.status == 'success') {
              queryClient.invalidateQueries(['client_tasks'])

              toast(response.data.msg, { type: "success" });
            } else {
              toast(response.data.msg, { type: "error" });
            }


          })

      }
    })




  }

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    formState,
    resetField,
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });


  const defaultValues = {
    date: null,
    status: null,
    cust_tag: null,
    task_tag: null,
    service: null,
    is_billed: null,
    task_type: null,
  };

  useEffect(() => {

    const parsed = queryString.parse(location.search);

    if (location.search) {
      //setPage(parseInt(parsed.page))
    } else {
      setPage(1)
      setFilter(defaultValues)
    }



  }, [location]);

  const onSubmitHandler = async (data) => {
    setPage(1)
    const obj = {};
    Object.entries(data).map(([key, val]) => {
      if (val) {
        obj[key] = Array.isArray(val) ? (val.map(itm => itm.value)).join(',') : val.label ? val.value + '::' + val.label : val
      }
    })

    const u = new URLSearchParams(obj).toString();

    if (location.search) {
      navigate(location.pathname + '?' + u, { replace: true })
    } else {
      navigate(location.pathname + "?" + u)
    }


  };


  async function fetchData(filter) {
    let currentUrlParams = new URLSearchParams(filter);
    currentUrlParams.set('client', pstate.client.id + '::user');

    const { data } = await instance.get(
      "/tasks?" + currentUrlParams.toString()
    );
    var dataa = data;
    return dataa;
  }


  const columns = [
    {
      name: 'Date',
      id: 'date',
      width: '100px',
      selector: row => row.date,
      sortable: true,
      sortField: 'date',
    },
    {
      name: 'Task',
      id: 'service',
      minWidth: '200px',
      selector: row => row.service,
      cell: row => (
        <div className="d-grid">
        {row.is_parent == null && <span className="d-block small text-secondary">{row.service}{row.period ? ` - ${row.period}` : ''}</span>}
          <OverlayTrigger overlay={row.task_description ? <Tooltip >{row.task_description}</Tooltip> : <></>} ><Link to={`/view-task/${row.id}`}><b>{row.is_parent == 1 ? row.service : row.title}</b></Link></OverlayTrigger>
          {row.is_parent == 1 && <span title="Task Period" className="d-block">{row.period}</span>}
        </div>),
      sortable: true,
      sortField: 'service',

    },


    {
      name: 'Due Date',
      sortable: true,
      width: '110px',
      id: 'due_date',
      sortField: 'due_date',
      selector: row => row.due_date,
      cell: row => (<span>{row.due_date}</span>)
    },
    {
      name: 'Target Date',
      sortable: true,
      width: '110px',
      id: 'target_date',
      sortField: 'target_date',
      selector: row => row.target_date,
      cell: row => (<span>{row.target_date}</span>)
    },
    {
      name: 'Completed on',
      sortable: true,
      width: '120px',
      id: 'completed_on',
      sortField: 'completed_on',
      selector: row => row.completed_on,
      cell: row => (<span>{row.completed_on}</span>)
    },

    {
      name: 'Users',
      minWidth: '120px',
      selector: row => row.users,
      cell: row => (<div><UiAvatarGroup items={row.users1}></UiAvatarGroup> </div>)
    },

    {
      name: 'Status',
      sortable: true,
      id: 'status',
      sortField: 'status',
      selector: row => row.status,
      cell: row => (<><span className={`badge ${row.status}`}>{row.status}</span>
        {row.verified == 'Yes' && <OverlayTrigger overlay={<Tooltip >Verified</Tooltip>} ><span className="text-success ms-2 sicon fs-6"><MdVerified /></span></OverlayTrigger>}
      </>)
    },



    {
      name: '',
      width: (window.innerWidth > 768 ? '0px' : 'auto'),
      selector: row => row.id,
      cell: row => (
        <div className="tableaction">
          <UiActionDropDown >
            <DropdownItem isDisabled={row.can_edit == 1 && row.verified == 'No' ? false : true} onClick={() => showstatusModal([row])}>Change Status</DropdownItem>
            <DropdownItem isDisabled={row.can_edit == 1 && row.verified == 'No' ? false : true} onClick={() => showduedateModal([row], 'due_date')}>Change Due Date</DropdownItem>
            <DropdownItem isDisabled={row.can_edit == 1 && row.verified == 'No' ? false : true} onClick={() => showduedateModal([row], 'target_date')}>Change Target Date</DropdownItem>

            <DropdownItem isDisabled={data.access.assign == 1 && row.verified == 'No' ? false : true} onClick={() => showuserModal([row])}>Assign Users</DropdownItem>
            <DropdownItem isDisabled={data.access.verify == 1 && row.verified == 'No' && row.status == 'Completed' ? false : true} onClick={() => showverifyModal([row])}>Verify</DropdownItem>
            <DropdownItem className="text-danger" isDisabled={row.can_delete == 1 && row.invoice_number == null ? false : true} onClick={() => handleDelete([row])}>Delete</DropdownItem>




          </UiActionDropDown>
        </div>


      )
    },


  ];


  async function fetchData1(id) {

    const { data } = await instance.get(
      "/filters?q=" + id
    );
    var dataa = data;
    return dataa;
  }





  const handlePageChange = (pg) => {

    setPage(pg)
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', pg);
    if (pg) {
      if (location.search) {
        navigate(location.pathname + '?' + currentUrlParams.toString(), { replace: true })
      } else {
        navigate(location.pathname + '?' + currentUrlParams.toString())
      }


    }
  };

  const handleSort = (column, sortDirection) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('sort_by', column.sortField);
    currentUrlParams.set('sort', sortDirection);
    if (location.search) {
      navigate(location.pathname + '?' + currentUrlParams.toString(), { replace: true })
    } else {
      navigate(location.pathname + '?' + currentUrlParams.toString())
    }

  };


  const { data, error, isError, isLoading, isFetching } = useQuery(["client_tasks", pstate.client.id, location.search], () => fetchData(location.search), {
    keepPreviousData: true,
  });


  const { data: f_data, isLoading: f_is_loading } = useQuery(["filters", 'S'], () => fetchData1('S'), {
    keepPreviousData: true,
  });



  useEffect(() => {

    if (f_data) {
      const defaultValues1 = {
        date: filter.date,
        task_type: filter.task_type,
        status: f_data.status_list && filter.status ? f_data.status_list.filter(({ value }) => filter.status.split(',').includes(value)) : null,
        service: f_data.services && filter.service ? f_data.services.filter(({ value }) => filter.service.split(',').includes(value.toString())) : null,

      };
      reset(defaultValues1)
    }
  }, [f_data, filter]);





  const clearFilter = () => {
    reset(defaultValues)
    navigate(location.pathname, { replace: true })
  }


  if (isLoading) {
    return <BgLoader />;
  }
  if (isError) {
    return <div>Error! {error.message}</div>;
  }
  if (data.status == 'error') {
    return (
      <PageError msg={data.msg} code={data.code} />
    )
  }

  return (
    <div className="card card-default">
      <div className="card-body">
      
        <form id="tasksfilterfrm" className="position-relative" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
          {f_is_loading == true && <InLoader />}
          {f_data && (
            <div className="p-3 bg-light rounded ">

              <div className="row">

                <div className="col"  style={{  minWidth: '260px' }}>
                  <label className="form-label">Date</label>
                  <Controller
                    name="date"
                    control={control}
                    defaultValue="All Time"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <UiDateRange

                        onUpdate={onChange}
                        value={value}
                        ref={ref}
                        name={name}

                      />
                    )}
                  />
                </div>



                <div className="col" style={{  minWidth: '200px' }}>
                  <Controller
                    name="service[]"
                    control={control}
                    render={({ field }) => (

                      <UiRSelect
                        //defaultValue={null}
                        options={f_data.services}
                        className=""

                        isMulti
                        message={errors.service?.message}
                        label="Service"
                        {...field}
                      />

                    )}
                  />
                </div>



                <div className={`col`} style={{  minWidth: '200px' }}>
                  <Controller
                    name="status[]"
                    control={control}
                    render={({ field }) => (

                      <UiRSelect

                        options={f_data.status_list}
                        className=""
                        isMulti
                        message={errors.status?.message}
                        label="Status"
                        {...field}
                      />

                    )}
                  />
                </div>

                <div className={`col`} style={{  minWidth: '100px' }}>
                    <UiSelect
                      label="Task Type"
                      options={['Parent Task', 'Sub Task']}
                      name="task_type"
                      message={errors.task_type?.message}
                      {...register('task_type')}
                    />
                  </div>


                <div className="col" style={{ paddingTop: '28px', minWidth: '220px' }}>
                  <div className="d-flex justify-content-end">


                    <UiActioButton className="fs-3 text-muted" title="Clear" tooltip="Reset Filter" action={() => clearFilter()} />
                    <UiActioButton type="submit" loading={state["loaderss"]} className="fs-3" tooltip="Apply Filter" title="Go" />
                    <UiButton className="btn btn-primary ms-2" onClick={() => navigate(`/task/new?client=${pstate.client.id}::${encodeURIComponent(pstate.client.name)}`)} title="New" icon="add"></UiButton>
                  </div>

                </div>
              </div>



            </div>
          )}
        </form>
      </div>
      <div className="card-body pt-0 tablecard">
        {isFetching == true && <InLoader />}
        <DataTable

          columns={columns}
          pagination
          paginationServer
          paginationPerPage="20"
          paginationDefaultPage={pagenum}
          paginationResetDefaultPage={true}
          paginationTotalRows={data.total}
          persistTableHead
          paginationComponentOptions={{ noRowsPerPage: true }}
          data={data.items}
          onChangePage={handlePageChange}
          clearSelectedRows={toggleCleared}
          noDataComponent={<TableZero title="Tasks" />}
          onSort={handleSort}
          defaultSortFieldId={parsed.sort_by ? parsed.sort_by : null}
          defaultSortAsc={parsed.sort == 'desc' ? false : true}
          sortServer
          disabled={tableDisable}
        />

      </div>
    </div>
  )

}

