import { useState, useEffect } from "react";
import NiceModal, { useModal, bootstrapDialog } from "@ebay/nice-modal-react";
import { UiTextArea, UiSelect, TableZero, UiDatePicker, UiButton, UiRichText, UiRSelect, BgLoader, PageError, InLoader } from "../ui";
import BootstrapModal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { toast } from "react-toastify";
import { instance } from "../axios";
import { useNavigate, useLocation } from "react-router-dom";
import { format, isValid, parse } from 'date-fns';



const ExportTally = NiceModal.create(
  ({ id, data }) => {
    const [state, setState] = useState({});
    const [loader, showLoader] = useState(false);
    const modal = useModal();



    const schema = yup.object().shape({

      from: yup.string().required('Select date from'),
      to: yup.string().required('Select date to'),
      entity: yup.object().nullable().required("Select billing entity"),

    });
    var handleSave = (e) => {
      setState({ ...state, loader: true });
      var form = document.querySelector("#tallyfrm");
      var data = new FormData(form);

      instance({
        method: "post",
        url: "/download_tally",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          setState({ ...state, loader: false });
          if (response.data.status == "error") {
            toast(response.data.msg, { type: "error" });
          }
          if (response.data.status == "success") {
            modal.hide()
            toast(response.data.msg, { type: "success" });
            window.open(response.data.url, '_blank');
          }
        })
        .catch(function (response) { });
    };
    const {
      register,
      handleSubmit,
      setError,
      watch,
      resetField,
      clearErrors,
      control,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });
    const onSubmitHandler = async (data) => {
      handleSave(data);
    };


    const fetchData = () => {

      instance
        .get("/get_tally_form")
        .then(function (response) {
          if (response.data.status == 'success') {
            setState({ ...state, ...response.data });

          } else {
            toast(response.data.msg, { type: "error" });
          }


        })
        .catch(function (error) { })
        .then(function () { });

    }



    const location = useLocation();
    useEffect(() => {
      if (modal.visible == true) {
        modal.hide();
      }
      fetchData();
    }, [location]);


    const toWatch = watch('to');
    const fromWatch = watch('from');


    return (
      <BootstrapModal className={state.modalb && `modalb`} {...bootstrapDialog(modal)}>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>Export Data to Tally</BootstrapModal.Title>
        </BootstrapModal.Header>
        {state.status ? (
          <form id="tallyfrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
            <BootstrapModal.Body>
              <p className="mb-3 text-secondary">Please select the date range to download the (receipt/invoice/client/service) for import into Tally.<br></br><br></br>
                Note: The date range should be within 1 year.</p>
              <Controller
                name="entity"
                control={control}

                render={({ field }) => (

                  <UiRSelect
                    required={true}
                    {...field}
                    options={state.entities}
                    className=""
                    message={errors.entity?.message}
                    label="Billing Entity"

                  />

                )}
              />
              <label class="form-label ">Date Range <span class="text-red font-bold text-lg">*</span></label>
              <div className="row">
                <div className="col">
                  <Controller
                    name="from"
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <UiDatePicker
                        placeholder="From"
                        onChange={onChange}
                        default_val={value}
                        maxDate={parse(toWatch, "dd-MM-yyyy", new Date())}
                        message={errors.from?.message}
                        dateFormat="dd-MM-yyyy"
                        ref={ref}
                        name={name}
                      />
                    )}
                  />
                </div>
                <div className="col">
                  <Controller
                    name="to"
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <UiDatePicker
                        placeholder="To"
                        onChange={onChange}
                        default_val={value}
                        minDate={parse(fromWatch, "dd-MM-yyyy", new Date())}
                        message={errors.to?.message}
                        dateFormat="dd-MM-yyyy"
                        ref={ref}
                        name={name}
                      />
                    )}
                  />
                </div>
              </div>


            </BootstrapModal.Body>
            <BootstrapModal.Footer>
              <UiButton loading={state["loader"]} title="Download" />
              <a className="btn btn-secondary" onClick={modal.hide}>Cancel</a>
            </BootstrapModal.Footer>
          </form>
        ) : <BgLoader />}
      </BootstrapModal>
    );
  }
);

export default ExportTally;
