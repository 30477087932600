import React, { useState, useEffect, useRef } from "react";
import { instance } from "../../axios";
import { rupees } from "../../helper";
import { UiActioButton, UiAvatarGroup, UiInput, UiRSelect, UiButton, BgLoader, UiTextArea, UiToggle, UiPopover, UiTFind } from "../../ui";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; import { Link, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Popover } from 'antd';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import { MdOutlineDragHandle, MdOutlinePerson } from "react-icons/md";
import { IoPersonOutline, IoCalendarClearOutline } from "react-icons/io5";


const DragHandle = sortableHandle(() => <span className="h2 pe-3 mb-0 text-secondary"><MdOutlineDragHandle /></span>);

const SortableItem = sortableElement((value) => {
  return (
    <div className="d-flex align-items-center bg-light p-3 rounded mb-2">
      <DragHandle />
      <div className="w-100 d-flex" {...value} />
    </div>
  )
});
const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});
export default function ServiceSubtask() {
  const [pstate, setPSate] = useOutletContext();
  const [bg_loader, setBGLoader] = useState(false);
  const [state, setState] = useState({ count: 0 });


  const [entry, setEntry] = useState([]);
  const schema = yup.object().shape({

    items: yup.array().of(
      yup.object().shape({
        name: yup.string().nullable().required("This is required field").max(250, 'Maximum character limit is 250'),
      })
    ),



  });

  const insertEntry = () => {
    // setState({ ...state, count: state.count + 1 })
    //setEntry([...entry, { id: state.count }])
    append()

  }
  const {
    register,
    getValues,
    unregister,
    resetField,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });


  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "items", // unique name for your Field Array
  });
  const [values, setValues] = useState(getValues('items'));

  const deleteEntry = (id) => {
    if (fields) {
      if (fields.length == 1) {
        handleDelete(pstate.service.id)
      }
    }

    remove(id)
  }
  const onSubmitHandler = async (data) => {
    handleSave(data);
  };


  const onSortEnd = ({ oldIndex, newIndex }) => {
    move(oldIndex, newIndex)

    // var newsort = arrayMove(entry, oldIndex, newIndex);
    //setEntry(newsort)
  };

  const handleDelete = (id) => {

    setBGLoader(true)
    instance
      .get("/delete_subtask_list?id=" + id)
      .then(function (response) {
        setBGLoader(false)
        if (response.data.status == 'success') {
          //toast(response.data.msg, { type: "success" });
          fetchData();
        } else {
          toast(response.data.msg, { type: "error" });
        }



      })




  }
  var handleSave = (e) => {
    setState({ ...state, loader: true });


    instance({
      method: "post",
      url: "/service_update_subtask",
      data: e,
    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          toast(response.data.msg, { type: "success" });

        }
      })
      .catch(function (response) { });
  };


  const fetchData = () => {
    instance
      .get("/service_subtasks?id=" + pstate.service.id)
      .then(function (response) {
        setState({ ...state, ...response.data });
        reset(response.data)


      })
      .catch(function (error) { })
      .then(function () { });

  }

  const items = watch('items');
  useEffect(() => {
    fetchData();
  }, []);



  return (
    <div className="card card-default">
      <div className="card-body">
        {bg_loader == true ? <BgLoader /> : (
          <>
            {state.items ? (

              <div>
                <form id="servicesubtaskfrm" onSubmit={handleSubmit(onSubmitHandler)} noValidate>

                  <div className="d-flex align-items-center mb-4">

                    {fields && fields.length > 0 && (
                      <>
                        <div className="flex-grow-1">
                          <div className="d-md-flex align-items-center">
                            <h2 className="mb-1 fs-4 me-3">Update Subtasks </h2>
                            <div className="d-flex">
                              <UiToggle
                                id="subtask_workflow"
                                title="Enable Workflow"
                                pclass="pb-0"
                                name="subtask_workflow"
                                {...register("subtask_workflow")}
                              />
                              <UiPopover msg="Once a subtask is completed, the next one will be automatically generated." />
                            </div>
                          </div>
                        </div>

                        <UiButton loading={state["loader"]} title="Save" />
                      </>
                    )}
                  </div>
                  <SortableContainer onSortEnd={onSortEnd} useDragHandle>
                    {fields && fields.length > 0 && fields.map((value, i) => (
                      <SortableItem key={i} index={i}  >
                        <div key={i} className="flex-grow-1">
                          <div className="row">
                            <div className="col">
                              <UiInput
                                placeholder="Title"
                                type="text"
                                className="mb-0"
                                name={`items[${i}].name`}
                                parentClass="mb-2"
                                message={errors.items?.[i]?.name?.message}
                                noerror="1"
                                //defaultValue={value.name}
                                {...register(`items.[${i}].name`)}
                              />

                              <UiTextArea
                                placeholder="Description"
                                parentClass="mb-0"
                                name={`items[${i}].description`}
                                {...register(`items.[${i}].description`)}
                              />


                            </div>
                            <div className="col-md-6" style={{ maxWidth: "300px" }}>
                              <div className="my-2">

                                <Popover placement="bottomLeft"
                                  content={<div style={{ width: "300px" }} className="d-flex align-items-center">
                                    <span>Creation Date + </span>
                                    <div style={{ width: "60px" }} className="mx-2">
                                      <UiInput
                                        type="number"
                                        className="mb-0"
                                        name={`items[${i}].due_date_days`}
                                        parentClass="mb-0"
                                        message={errors.items?.[i]?.due_date_days?.message}
                                        noerror="1"
                                        //defaultValue={value.name}
                                        {...register(`items.[${i}].due_date_days`)}
                                        onChange={(e) => {
                                          setValue(`items.${i}.due_date_days`, e.target.value); // Directly update the value
                                        }}
                                      />
                                    </div>
                                    <span> Days</span>
                                  </div>
                                  }
                                  title="Due Date"
                                  trigger="click"

                                >

                                  {items[i].due_date_days ? (
                                    <div>Due Date: Creation Date + {items[i].due_date_days} Days<UiActioButton className="bg-transparent" title="Edit" ></UiActioButton></div>
                                  ) : <div className="d-flex align-items-center c-pointer"><IoCalendarClearOutline className="na_icon" /><div className="ms-2 text-secondary">No Due Date</div></div>}
                                </Popover>

                                <div className="my-1">
                                  <Popover placement="bottomLeft"
                                    content={<div style={{ width: "300px" }} className="d-flex align-items-center">
                                      <span>Creation Date + </span>
                                      <div style={{ width: "60px" }} className="mx-2">
                                        <UiInput
                                          type="number"
                                          className="mb-0"
                                          name={`items[${i}].target_date_days`}
                                          parentClass="mb-0"
                                          message={errors.items?.[i]?.target_date_days?.message}
                                          noerror="1"
                                          {...register(`items.[${i}].target_date_days`)}
                                          onChange={(e) => {
                                            setValue(`items.${i}.target_date_days`, e.target.value); // Directly update the value
                                          }}
                                        />
                                      </div>
                                      <span> Days</span>
                                    </div>
                                    }
                                    title="Target Date"
                                    trigger="click"

                                  >

                                    {(items[i].target_date_days || Number.isInteger(items[i].target_date_days)) ? (
                                      <div>Target Date: Creation Date + {items[i].target_date_days} Days<UiActioButton className="bg-transparent" title="Edit" ></UiActioButton></div>
                                    ) : <div className="d-flex align-items-center c-pointer"><IoCalendarClearOutline className="na_icon" /><div className="ms-2 text-secondary">No Target Date</div></div>}
                                  </Popover>
                                </div>
                                <div className="d-flex">
                                  {items[i].users &&
                                    <div style={{ height: "30px" }} ><UiAvatarGroup items={items[i].users}></UiAvatarGroup></div>
                                  }
                                  <Popover
                                    content={<div style={{ width: "320px" }}>
                                      <Controller
                                        name={`items[${i}].users[]`}
                                        control={control}
                                        render={({ field }) => (

                                          <UiRSelect
                                            {...field}
                                            className=""
                                            isMulti
                                            message={errors.items?.[i]?.users?.message}
                                            label=""

                                            options={pstate.service.all_users}

                                            onChange={(e) => {
                                              field.onChange(e);
                                              setValue(`items.${i}.users`, e); // Directly update the value
                                            }}

                                          />

                                        )}
                                      />

                                    </div>
                                    }
                                    title="Users"
                                    trigger="click"

                                  >
                                    {items[i].users ? (
                                      <div><UiActioButton className="bg-transparent" title="Edit" ></UiActioButton></div>
                                    ) : <div className="d-flex align-items-center c-pointer"><IoPersonOutline className="na_icon" /><div className="ms-2 text-secondary">No Users</div></div>}
                                  </Popover>
                                </div>
                              </div>
                            </div>
                          </div>


                        </div>
                        <div className="d-flex align-items-center">
                          <UiButton className="btn btn-outline-danger ms-3" onClick={() => deleteEntry(i)} title="" icon="delete"></UiButton>
                        </div>
                      </SortableItem>
                    ))}


                  </SortableContainer>

                  {pstate.service.id && (<input type="hidden" value={pstate.service.id} name="service" {...register("service")} />)}

                </form>
                <div className="text-center">
                  <UiButton className="btn btn-outline-success" onClick={() => insertEntry()} title="Add Subtask" icon="add"> </UiButton>
                </div>
              </div>
            ) : <BgLoader />}
          </>
        )}
      </div>
    </div>
  )

}

