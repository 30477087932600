import React, { useState, useEffect } from "react";
import { instance } from "../../axios";
import { UiSelect, UiInput, UiButton, UiTextArea, UiDatePicker, UiRSelect, UiImageUpload, UiToggle, BgLoader, PageHeader, PageError, TableZero } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useQueryClient } from 'react-query';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import chroma from 'chroma-js';
import Select, { StylesConfig } from 'react-select';
import { Helmet } from "react-helmet";
import { format, isValid, parse } from 'date-fns';
import { useStore } from "../../state.js";
import DataTable from 'react-data-table-component';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { AiOutlineSearch } from 'react-icons/ai'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';



export default function Client() {
  let { id } = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});
  const [gst_party, setGSTparty] = useState(null);
  const [gst_loader, setGSTloader] = useState(false);
  const [photo, setPhoto] = useState(null);
  const location = useLocation();
  const schema = yup.object().shape({

    name: yup.string().required("Client Name is required").max(60, 'Maximum character limit is 60'),
    type: yup.string().required("Select client type"),
    address: yup.string().max(160, 'Maximum character limit is 160'),
    city: yup.string().max(30, 'Maximum character limit is 30'),
    file: yup.string().nullable().max(10, 'Maximum character limit is 10'),
    contact_person: yup.string().max(50, 'Maximum character limit is 50'),
    mobile: yup.string().nullable().when('mobile', {
      is: (value) => value?.length,
      then: (rule) => rule.matches(/^[0-9]+$/, "Enter 10 digit mobile number")
        .min(10, "Enter 10 digit mobile number")
        .max(10, "Enter 10 digit mobile number"),
    }),
    mobile_2: yup.string().nullable().when('mobile_2', {
      is: (value) => value?.length,
      then: (rule) => rule.matches(/^[0-9]+$/, "Enter 10 digit mobile number")
        .min(10, "Enter 10 digit mobile number")
        .max(10, "Enter 10 digit mobile number"),
    }),
    pincode: yup.string().nullable().when('pincode', {
      is: (value) => value?.length,
      then: (rule) => rule.matches(/^[0-9]+$/, "Enter 6 digit pin code number")
        .min(6, "Enter 6 digit pin code number")
        .max(6, "Enter 6 digit pin code number"),
    }),
    email: yup.string().nullable().when('email', {
      is: (value) => value?.length,
      then: (rule) => rule.email("Enter valid email address")

    }),
    //state: yup.string().required("Select state"),

    gst: yup
      .string()
      .nullable()
      .when("gst", {
        is: (value) => value?.length,
        then: (rule) =>
          rule
            .matches(
              /^[0-9]{2}[0-9A-Za-z]{13}$/,
              "Enter valid GST number"
            )
            .required("Enter GST number"),
      }),

    pan: yup
      .string()
      .nullable()
      .when("pan", {
        is: (value) => value?.length,
        then: (rule) =>
          rule
            .matches(
              /^[0-9A-Za-z]{9}[A-Za-z]{1}$/,
              "Enter valid PAN number"
            )
            .required("Enter PAN number"),
      }),

    opening_balance: yup.bool(),
    ob_date: yup.string().nullable().when('opening_balance', {
      is: true,
      then: yup.string().nullable().required('Select opening balance date')
    }),

    ob_amount: yup.string().nullable().when('opening_balance', {
      is: true,
      then: yup.string().nullable().matches(/^[0-9]+$/, "Enter opening balance amount").required('Enter opening balance amount')
    }),

    ob_type: yup.string().nullable().when('opening_balance', {
      is: true,
      then: yup.string().nullable().required('Select transaction type')
    }),


    cfr: yup.array().of(
      yup.object().nullable().shape({
        val: yup.string().nullable()
          .required('This is required field').max(50, 'Maximum character limit is 50'),
      })
    ),



  }, [["mobile", "mobile"], ["mobile_2", "mobile_2"], ["email", "email"], ["gst", "gst"], ["pan", "pan"], ["pincode", "pincode"]]);


  var handleSave = (e) => {
    setState({ ...state, loader: true });

    const datatopost = { ...e, fields: Object.keys(dirtyFields) };

    instance({
      method: "post",
      url: "/update_client",
      data: datatopost,
      headers: { "Content-Type": "application/json" },
    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.status == "error") {
          toast(response.data.msg, { type: "error" });
        }
        if (response.data.status == "success") {
          queryClient.invalidateQueries(['clients'])
          useStore.setState({ load_clients: false });
          toast(response.data.msg, { type: "success" });
          if (response.data.id) {
            navigate("/view-client/" + response.data.id)
          }


        }
      })
      .catch(function (response) { });
  };
  const {
    register,
    handleSubmit,
    setError,
    watch,
    unregister,
    getValues,
    clearErrors,
    control,
    formState: { errors, dirtyFields },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    handleSave(data);
  };


  const defaultValues = {
    photo: '',
    status: '',
    name: '',
    type: '',
    mobile: '',
    mobile_2: '',
    email: '',
    pan: '',
    gst: '',
    file: '',
    group: '',
    address: '',
    city: '',
    pincode: '',
    state: '',
    users: '',
    tags: '',
    opening_balance: '',
    contact_person: '',
    dob: null,
    cfr: null,
    cf: null,

  };

  const colourStyles: StylesConfig<ColourOption, true> = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      if (data.color == undefined || data.color == null) {
        data.color = '#000000';
      }
      const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? data.color
            : isFocused
              ? color.alpha(0.1).css()
              : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
            ? chroma.contrast(color, 'white') > 2
              ? 'white'
              : 'black'
            : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      if (data.color == undefined || data.color == null) {
        data.color = '#000000';
      }
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
    }),
  };


  const columns = [
    {
      name: 'Service',
      selector: row => row.name,
      cell: row => (<b className="text-dark">{row.name}</b>)

    },



    {
      name: 'Enable',
      selector: row => row.name,
      width: '80px',
      cell: (row, index) => (
        <>
          <input type="hidden" value={row.id} name={`service[${index}].id`} {...register(`service.[${index}].id`)}></input>
          <input type="hidden" value={row.selected} name={`service[${index}].selected`} {...register(`service.[${index}].selected`)}></input>
          {row.package == 1 ?
            <OverlayTrigger overlay={<Tooltip >Service assigned with package can't be disabled directly.</Tooltip>}>
              <div className="opacity-75">
              <UiToggle
                id={index}
                pclass="pb-0"
                checked={true}
                name={`service[${index}].val`}
                {...register(`service.[${index}].val`)}
              />
              </div>
            </OverlayTrigger>
            :
            <UiToggle
              id={index}
              pclass="pb-0"
              name={`service[${index}].val`}
              {...register(`service.[${index}].val`)}
            />
          }
        </>
      )
    },
  ];

  const fetchData = () => {
    instance
      .get("/get_client?id=" + id)
      .then(function (response) {
        setState({ ...state, ...response.data });
        if (response.data.status == 'success') {

        } else {
          toast(response.data.msg, { type: "error" });
        }


      })
      .catch(function (error) { })
      .then(function () { });

  }

  const findGSTIN = (gstin) => {
    setGSTloader(true)

    var data = new FormData();
    data.append('id', gstin)
    instance({
      method: "post",
      url: "/gst_validate",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        setGSTloader(false)
        if (response.data.status == 'success') {
          setGSTparty(response.data.party)
          toast(response.data.msg, { type: "success" });
        } else {
          toast(response.data.msg, { type: "error" });
        }
      })
      .catch(function (response) { });




  }

  const groupModal = useModal('group');

  const addGroup = () => {
    groupModal.show().then((res) => {
      setState({ ...state, groups: [...state.groups, res.group] })
      reset({ ...getValues(), group_id: res.group })
    });

  }

  const tagModal = useModal('user');

  const addTag = () => {
    tagModal.show().then((res) => {
      setState({ ...state, tags: [...state.tags, res.item] })
      reset({ ...getValues(), tags: [res.item] })
    });

  }

  useEffect(() => {
    fetchData();

  }, [location]);


  const gstclientmodal = useModal('gst-client');

  const showGSTclientModal = () => {
    gstclientmodal.show({}).then((res) => {
      reset(res.party_info)
      setGSTparty(null)
    });

  }



  useEffect(() => {
    return () => {
      reset(defaultValues)
    }
  }, [location]);

  useEffect(() => {
    reset(state.client)
  }, [state.client]);


  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />

    )
  }

  const watchob = watch('opening_balance');
  const watch_type = watch('type');
  const watch_gst = watch('gst');




  return (
    <>
      <Helmet>
        <title>
          {id == 'new' ? 'New' : 'Edit'} Client | Practive
        </title>
      </Helmet>
      <div>
        {state.states ? (
          <div>
            <PageHeader title={`${id == 'new' ? 'New' : 'Edit'} Client`} parent="Clients" link="/clients">
              <div>
                <UiButton className="btn btn-primary" onClick={() => showGSTclientModal()} title="Autofill Using GSTIN" icon="add"></UiButton>
              </div>

            </PageHeader>



            <form onSubmit={handleSubmit(onSubmitHandler)} noValidate={"novalidate"}>

              <div className="row">
                <div className="col-lg-6">


                  <div className="card card-default mb-4">
                    <div className="card-header">
                      Basic Details
                    </div>
                    <div className="card-body">
                      <Controller
                        name="photo"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <UiImageUpload
                            lcol="4"
                            icol="8"
                            label="Photo"
                            name="photo"
                            max_width="200px"
                            file={value}
                            setFile={onChange}
                            onClear={() => onChange(null)}
                            width={250}
                            height={250}
                            ratio="ratio-1x1"
                          />
                        )}
                      />
                      <div className="d-flex">
                        <span className="me-2">Is Active?</span>
                        <UiToggle
                          id="isactive"
                          name="status"
                          value="1"

                          {...register("status")}
                        />
                      </div>
                      <div className="row">
                        <div className="col-sm-8">
                          <UiInput
                            label="Client Name"
                            type="text"
                            required={true}
                            name="name"
                            message={errors.name?.message}
                            {...register("name")}
                          />
                        </div>
                        <div className="col-sm-4">
                          <UiInput
                            label="File No."
                            type="text"
                            name="file"
                            message={errors.file?.message}
                            {...register("file")}
                          />

                        </div>
                      </div>

                      <input type="hidden" defaultValue={id} name="id" {...register("id")} />
                      <div className="row">
                        <div className="col-sm-6">
                          <UiSelect
                            label="Type"
                            required={true}
                            name="type"
                            options={state.types}
                            message={errors.type?.message}
                            {...register("type")}
                          />
                        </div>
                        <div className="col-sm-6">
                          <Controller
                            name="group_id"
                            control={control}
                            render={({ field }) => (

                              <UiRSelect
                                {...field}
                                options={state.groups}
                                className=""
                                isClearable={true}
                                message={errors.group_id?.message}
                                label="Select Group"
                                addnew={1}
                                handleNew={() => addGroup()}

                              />

                            )}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <UiInput
                            label="PAN"
                            type="text"
                            name="pan"
                            message={errors.pan?.message}
                            {...register("pan")}
                          />
                        </div>
                        <div className="col-sm-6 d-flex">
                          <div className="flex-fill">
                            <UiInput
                              label="GSTIN"
                              type="text"
                              name="gst"
                              footer_text={gst_party}
                              message={errors.gst?.message}
                              {...register(`gst`, { onChange: (e) => { setGSTparty(null) } })}
                            />
                          </div>
                          <div className="ms-2" style={{ paddingTop: '27px' }}>
                            <UiButton loading={gst_loader} className="btn btn-outline-primary" type="button" onClick={() => findGSTIN(watch_gst)} disabled={(watch_gst && watch_gst.length == 15 && !gst_party) ? false : true} title={<AiOutlineSearch />} hideloadmsg={true}></UiButton>
                          </div>
                        </div>


                      </div>




                      <div className="row">

                        <div className={`col-sm-6 ${watch_type == 'Individual' && 'd-none'}`}>
                          <UiInput
                            label="Contact Person Name"
                            type="text"
                            footer_text="This name will be used in birthday messages."
                            name="contact_person"
                            message={errors.contact_person?.message}
                            {...register("contact_person")}
                          />
                        </div>

                        <div className="col">
                          <Controller
                            name="dob"
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                              fieldState: { invalid, isTouched, isDirty, error },
                              formState,
                            }) => (
                              <UiDatePicker
                                label="Date of Birth"
                                onChange={onChange}
                                default_val={value}
                                message={errors.dob?.message}
                                dateFormat="dd-MM-yyyy"
                                ref={ref}
                                name={name}
                              />
                            )}
                          />
                        </div>
                      </div>


                      <Controller
                        name="users[]"
                        control={control}
                        render={({ field }) => (

                          <UiRSelect
                            {...field}
                            options={state.users}
                            className=""
                            isMulti
                            message={errors.users?.message}
                            label="Users"
                          />

                        )}
                      />

                      <Controller
                        name="tags[]"
                        control={control}
                        render={({ field }) => (

                          <UiRSelect
                            {...field}
                            isMulti
                            className=""
                            message={errors.tags?.message}
                            label="Tags"
                            options={state.tags}
                            styles={colourStyles}
                            addnew={1}
                            handleNew={() => addTag()}

                          />

                        )}
                      />







                    </div>
                  </div>

                </div>
                <div className="col-lg-6">

                  <div className="card card-default mb-4">
                    <div className="card-header">
                      Contact Details
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-6">
                          <UiInput
                            label="Mobile No."
                            type="text"
                            name="mobile"
                            message={errors.mobile?.message}
                            {...register("mobile")}
                          />
                        </div>
                        <div className="col-sm-6">
                          <UiInput
                            label="Secondary Mobile No."
                            type="text"
                            name="mobile_2"
                            message={errors.mobile_2?.message}
                            {...register("mobile_2")}
                          />

                        </div>

                      </div>
                      <UiInput
                        label="Email"
                        type="email"
                        name="email"
                        message={errors.email?.message}
                        {...register("email")}
                      />
                      <UiTextArea
                        label="Address"
                        name="address"
                        message={errors.address?.message}
                        {...register("address")}
                      />

                      <div className="row">

                        <div className="col-sm-4">
                          <UiInput
                            label="City"
                            type="text"
                            name="city"
                            message={errors.city?.message}
                            {...register("city")}
                          />
                        </div>
                        <div className="col-sm-4">
                          <UiInput
                            label="Pincode"
                            type="text"
                            name="pincode"
                            message={errors.pincode?.message}
                            {...register("pincode")}
                          />
                        </div>
                        <div className="col-sm-4">
                          <UiSelect
                            label="State"
                            name="state"
                            //required={true}
                            options={state.states}
                            message={errors.state?.message}
                            {...register("state")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card card-default mb-4">
                    <div className="card-header">
                      Opening Balance
                    </div>
                    <div className="card-body">

                      <div className="d-flex">
                        <span className="me-2">Set Opening Balance</span>
                        <UiToggle
                          id="openingbal"
                          name="opening_balance"

                          {...register("opening_balance")}
                        />
                      </div>
                      {watchob && (
                        <div className="p-3 rounded bg-light">
                          <div className="row">

                            <div className="col-sm-4">
                              <Controller
                                name="ob_date"
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, name, ref },
                                  fieldState: { invalid, isTouched, isDirty, error },
                                  formState,
                                }) => (
                                  <UiDatePicker
                                    label="Balance as on"
                                    onChange={onChange}
                                    required={true}
                                    default_val={value}
                                    message={errors.ob_date?.message}
                                    dateFormat="dd-MM-yyyy"
                                    maxDate={parse(state.maxbal, "dd-MM-yyyy", new Date())}
                                    ref={ref}
                                    name={name}
                                  />
                                )}
                              />
                            </div>
                            <div className="col-sm-4">
                              <UiInput
                                label="Opening Balance"
                                type="text"
                                required={true}
                                name="ob_amount"
                                icon="inr"
                                message={errors.ob_amount?.message}
                                {...register("ob_amount")}
                              />
                            </div>
                            <div className="col-sm-4">
                              <UiSelect
                                label="Transaction Type"
                                required={true}
                                options={["Debit", "Credit"]}
                                name="ob_type"
                                message={errors.ob_type?.message}
                                {...register(`ob_type`)}
                              />


                            </div>
                          </div>
                        </div>
                      )}




                    </div>
                  </div>
                </div>
              </div>


              <div className="row">
                <div className="col-lg-6">

                  <div className="card card-default mb-4">
                    <div className="card-header">
                      Recurring Services
                    </div>
                    <div className="card-body">
                      <SimpleBar style={{ height: 500 }}>
                        <DataTable
                          columns={columns}
                          data={state.recurring_services}
                          noDataComponent={<TableZero title="Recurring Services" />}
                        />
                      </SimpleBar>

                    </div>
                  </div>
                </div>
                <div className="col-lg-6">


                  {state.custom_fields && state.custom_fields.length > 0 &&
                    <div className="card card-default mb-4">
                      <div className="card-header">
                        Custom Fields
                      </div>
                      <div className="card-body">

                        {state.custom_fields.map((val, index) => (
                          <div key={index}>
                            {val.field_type == 'Select' ? (
                              <>
                                <UiSelect
                                  label={val.field_name}
                                  options={val.options}
                                  required={val.required ? true : false}
                                  //defaultValue={val.value}
                                  name={`${val.required ? 'cfr' : 'cf'}.[${index}].val`}
                                  message={val.required ? errors.cfr?.[index]?.val?.message : errors.cf?.[index]?.val?.message}
                                  {...register(`${val.required ? 'cfr' : 'cf'}.[${index}].val`)}
                                />


                              </>

                            ) : (

                              <>
                                <UiInput
                                  label={val.field_name}
                                  type="text"
                                  //defaultValue={val.value}
                                  name={`${val.required ? 'cfr' : 'cf'}.[${index}].val`}
                                  required={val.required ? true : false}
                                  message={val.required ? errors.cfr?.[index]?.val?.message : errors.cf?.[index]?.val?.message}
                                  {...register(`${val.required ? 'cfr' : 'cf'}.[${index}].val`)}
                                />
                              </>
                            )}
                            <input type="hidden" defaultValue={val.id} name={`${val.required ? 'cfr' : 'cf'}.[${index}].id`} {...register(`${val.required ? 'cfr' : 'cf'}.[${index}].id`)} />
                          </div>
                        ))}



                      </div>
                    </div>
                  }
                </div>
              </div>


              <UiButton loading={state["loader"]} title="Save" />
            </form>

          </div>

        ) : <BgLoader />}
      </div>
    </>
  );
}
