import React, { useState, useEffect } from "react";
import { instance } from "../../axios";
import { UiSelect, PageHeader, UiButton, UiTextArea, UiToggle, PageError, UiDatePicker, UiRSelect, UiClientSelect, BgLoader, Toastcontent } from "../../ui";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"; import { NavLink, useNavigate, useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useQueryClient } from 'react-query';
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import chroma from 'chroma-js';
import Select, { StylesConfig } from 'react-select';
import { Helmet } from "react-helmet";
import queryString from 'query-string';


export default function Task() {
  let { id } = useParams();
  let navigate = useNavigate();
  const queryClient = useQueryClient()
  const [state, setState] = useState({});
  const location = useLocation();

  const parsed = queryString.parse(location.search);


  const schema = yup.object().shape({

    //client: yup.mixed().nullable().required("Client is required"),
    client: yup.array().min(1, 'Client is required')
      .of(
        yup.object().shape({
          label: yup.string(),
          value: yup.string().required(),
        })).required("Client is required"),
    service: yup.object().nullable().required("Select Service"),
    description: yup.string().max(1000, 'Maximum character limit is 1000'),
    fyear: yup.string().when('service', {
      is: (value) => value && value.freq == 'Yearly',
      then: (rule) => rule.required("Select year")
    }),

    month_year: yup.string().when('service', {
      is: (value) => value && value.freq == 'Monthly',
      then: (rule) => rule.required("Select month & year")
    }),

    quarter: yup.string().when('service', {
      is: (value) => value && value.freq == 'Quarterly',

      then: (rule) => rule.required("Select quarter")


    }),
    hy_period: yup.string().when('service', {
      is: (value) => value && value.freq == 'Half-Yearly',

      then: (rule) => rule.required("Select period")


    }),
    year: yup.string().when('service', {
      //is: (value) => value && value.freq == 'Quarterly',
      is: (service) => service && ['Quarterly', 'Half-Yearly'].includes(service.freq),

      then: (rule) => rule.required("Select year")


    }),

    msg: yup.string().nullable().when('doc_req', {
      is: (value) => value == 1,

      then: (rule) => rule.required("Document request message is required")


    }),





  });
  var handleSave = (e) => {
    setState({ ...state, loader: true });


    instance({
      method: "post",
      url: "/update_task",
      data: e,
      headers: { "Content-Type": "application/json" },
    })
      .then(function (response) {
        setState({ ...state, loader: false });
        if (response.data.status == "error") {
          toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Client" />, { type: "error" });
        }
        if (response.data.status == "success") {
          queryClient.invalidateQueries(['tasks'])
          toast(<Toastcontent msg={response.data.msg} errors={response.data.errors} type="Client" />, { type: "success" });
          if (id == 'new') {
            navigate(-1)
          }

        }
      })
      .catch(function (response) { });
  };
  const {
    register,
    handleSubmit,
    setError,
    watch,
    resetField,
    getValues,
    clearErrors,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (data) => {
    handleSave(data);
  };



  const colourStyles: StylesConfig<ColourOption, true> = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      if (data.color == undefined || data.color == null) {
        data.color = '#000000';
      }
      const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? data.color
            : isFocused
              ? color.alpha(0.1).css()
              : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
            ? chroma.contrast(color, 'white') > 2
              ? 'white'
              : 'black'
            : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      if (data.color == undefined || data.color == null) {
        data.color = '#000000';
      }
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
    }),
  };



  const fetchData = () => {
    instance
      .get("/get_task?id=" + id)
      .then(function (response) {
        setState({ ...state, ...response.data });
        if (response.data.status == 'success') {

        } else {
          toast(response.data.msg, { type: "error" });
        }


      })
      .catch(function (error) { })
      .then(function () { });

  }



  useEffect(() => {
    reset()
    fetchData();
  }, [location]);

  useEffect(() => {
    reset(state.client)

  }, [state.client]);


  const tagModal = useModal('user');

  const addTag = () => {
    tagModal.show().then((res) => {
      setState({ ...state, tags: [...state.tags, res.item] })
      reset({ ...getValues(), tags: [res.item] })
    });

  }

  const [loadedOptions, setOptions] = useState({});
  const loadOptions = (inputValue, callback) => {

    if (inputValue.length) {
      instance
        .get("/fetch_clients?active=1&s=" + inputValue)
        .then(function (response) {
          if (response.data.status == 'success') {
            // response.data.items;
            callback(response.data.items);
            setOptions(response.data.items)
          } else {
            toast(response.data.msg, { type: "error" });
          }


        })

    }
  };

  const client_w = watch('client');
  const doc_req_w = watch('doc_req');
  useEffect(() => {
    if (client_w == null) {
      setOptions({})
    }

  }, [client_w]);


  useEffect(() => {

    if (parsed && parsed.client) {
      const defaultValues1 = {
        client: ([{ value: parseInt(parsed.client.split('::')[0]), label: parsed.client.split('::')[1] }]),

      };
      reset(defaultValues1)
    }
    if (parsed && parsed.clients) {
      const defaultValues1 = {
        client: (parsed.clients.split(',').map(x => ({ value: parseInt(x) }))),

      };
      reset(defaultValues1)
    }
  }, []);




  const watchservice = watch('service');

  useEffect(() => {
    if (watchservice && watchservice.req_msg) {
      resetField('msg', { defaultValue: watchservice.req_msg });
    } else {
      resetField('msg', { defaultValue: '' });
    }
  }, [watchservice]);


  if (state.status == 'error') {
    return (
      <PageError msg={state.msg} code={state.code} />
    )
  }

  return (
    <>
      <Helmet>
        <title>
          {id == 'new' ? 'New' : 'Edit'} Task | Practive
        </title>
      </Helmet>
      <div>
        {state.services ? (
          <div>

            <PageHeader title={`${id == 'new' ? 'New' : 'Edit'} Task`} parent="Tasks" link="/tasks">
            </PageHeader>



            <form onSubmit={handleSubmit(onSubmitHandler)} noValidate={"novalidate"}>

              <div className="card card-default mb-4">

                <div className="card-body">


                  <Controller
                    name="client"
                    control={control}
                    render={({ field }) => (

                      <UiClientSelect
                        {...field}
                        required={true}
                        lcol="3"
                        icol="9"
                        active="1"
                        message={errors.client?.message}
                        label="Client"
                        mode='multiple'
                        maxTagCount='responsive'
                        fetch_onload={true}
                      />

                    )}
                  />





                  <input type="hidden" defaultValue={id} name="id" {...register("id")} />


                  <Controller
                    name="service"
                    control={control}
                    render={({ field }) => (

                      <UiRSelect
                        {...field}
                        lcol="3"
                        icol="9"
                        required={true}
                        options={state.services}
                        className=""
                        message={errors.service?.message}
                        label="Service"
                      />

                    )}
                  />


                  {watchservice && watchservice.freq == 'Yearly' && (



                    <UiSelect
                      label="Select Year"
                      lcol="3"
                      icol="9"
                      required={true}
                      options={state.years}
                      //defaultValue={val.value}
                      name="fyear"
                      message={errors.fyear?.message}
                      {...register('fyear')}
                    />


                  )}

                  {watchservice && watchservice.freq == 'Quarterly' && (
                    <>


                      <UiSelect
                        lcol="3"
                        icol="9"
                        required={true}
                        label="Select Quarter"
                        options={['Apr-Jun', 'Jul-Sep', 'Oct-Dec', 'Jan-Mar']}
                        //defaultValue={val.value}
                        name="quarter"
                        message={errors.quarter?.message}
                        {...register('quarter')}
                      />

                      <Controller
                        name="year"
                        control={control}

                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <UiDatePicker
                            label="Select Year"
                            onChange={onChange}
                            required={true}
                            lcol="3"
                            icol="9"
                            default_val={value}
                            message={errors.year?.message}
                            dateFormat="yyyy"
                            showYearPicker
                            // showQuarterYearPicker
                            ref={ref}
                            name={name}
                          />
                        )}
                      />

                    </>
                  )}

                  {watchservice && watchservice.freq == 'Half-Yearly' && (
                    <>


                      <UiSelect
                        lcol="3"
                        icol="9"
                        required={true}
                        label="Select Period"
                        options={['Apr-Sep', 'Oct-Mar']}
                        //defaultValue={val.value}
                        name="hy_period"
                        message={errors.hy_period?.message}
                        {...register('hy_period')}
                      />

                      <Controller
                        name="year"
                        control={control}

                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => (
                          <UiDatePicker
                            label="Select Year"
                            onChange={onChange}
                            required={true}
                            lcol="3"
                            icol="9"
                            default_val={value}
                            message={errors.year?.message}
                            dateFormat="yyyy"
                            showYearPicker
                            // showQuarterYearPicker
                            ref={ref}
                            name={name}
                          />
                        )}
                      />

                    </>
                  )}

                  {watchservice && watchservice.freq == 'Monthly' && (


                    <Controller
                      name="month_year"

                      control={control}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                      }) => (
                        <UiDatePicker
                          label="Select Month & Year"
                          onChange={onChange}
                          required={true}
                          default_val={value}
                          lcol="3"
                          icol="9"
                          message={errors.month_year?.message}
                          dateFormat="MMM - yyyy"
                          showMonthYearPicker
                          // showQuarterYearPicker
                          ref={ref}
                          name={name}
                        />
                      )}
                    />

                  )}



                  <Controller
                    name="due_date"
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <UiDatePicker
                        label="Due Date"
                        onChange={onChange}
                        lcol="3"
                        icol="9"
                        default_val={value}
                        message={errors.due_date?.message}
                        dateFormat="dd-MM-yyyy"
                        ref={ref}
                        name={name}
                      />
                    )}
                  />


                  <Controller
                    name="target_date"
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <UiDatePicker
                        label="Target Date"
                        onChange={onChange}
                        lcol="3"
                        icol="9"
                        default_val={value}
                        message={errors.target_date?.message}
                        dateFormat="dd-MM-yyyy"
                        ref={ref}
                        name={name}
                      />
                    )}
                  />


                  {state.assign_task == 1 &&
                    <Controller
                      name="users[]"
                      control={control}
                      render={({ field }) => (

                        <UiRSelect
                          {...field}
                          options={state.users}
                          className=""
                          lcol="3"
                          icol="9"
                          isMulti
                          message={errors.users?.message}
                          label="Users"
                        />

                      )}
                    />
                  }
                  <Controller
                    name="tags[]"
                    control={control}
                    render={({ field }) => (

                      <UiRSelect
                        {...field}
                        isMulti
                        className=""
                        lcol="3"
                        icol="9"
                        message={errors.tags?.message}
                        label="Tags"
                        options={state.tags}
                        styles={colourStyles}
                        addnew={1}
                        handleNew={() => addTag()}

                      />

                    )}
                  />

                  <div className="row">
                    <div className="col-sm-3">
                      <label className="form-label">Create Doc. Collection Request</label>
                    </div>
                    <div className="col-sm-9">
                      <UiToggle
                        id="createdocrq"
                        name="doc_req"

                        {...register("doc_req")}
                      />
                    </div>
                  </div>
                  <div className={doc_req_w == 1 ? "" : "d-none"}>

                    <UiTextArea
                      lcol="3"
                      icol="9"
                      required={true}
                      label="Doc. Collection Request Message"
                      name="msg"
                      message={errors.msg?.message}
                      {...register("msg")}
                    />

                  </div>

                  <UiTextArea
                    lcol="3"
                    icol="9"
                    label="Description"
                    name="description"
                    message={errors.description?.message}
                    {...register("description")}
                  />
                </div>
              </div>
              <UiButton loading={state["loader"]} title="Save" />
            </form>

          </div>

        ) : <BgLoader />}
      </div>
    </>
  );
}